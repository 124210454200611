import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import NFTDetail from '../pages/NFTDetail';
import Poseidon from '../pages/Poseidon';
import Pot from '../pages/Pot';
import EarlyAdopter from '../pages/EarlyAdopter';
import ScrollIntoView from '../components/ScrollIntoView';
import TokenMigration from '../pages/TokenMigration/TokenMigration';

function Routes() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/buyliq'} component={Home} />
        <Route exact path={'/bridge'} component={Home} />
        <Route exact path={'/cmcairdrop'} component={Home} />
        <Route exact path={'/nft-detail/:id?'} component={NFTDetail} />
        <Route exact path={'/poseidon'} component={Poseidon} />
        <Route exact path={'/earlyadopter'} component={EarlyAdopter} />
        <Route path="/pot" component={Pot} />
        <Route path="/migrate" component={TokenMigration} />

        <Redirect to={'/'} />
      </Switch>
    </ScrollIntoView>
  );
}

export default Routes;
