// wallet-connect.ts
import Web3 from 'web3';

// This is the SDK provided by Wallet Connect
import WalletConnectProvider from '@walletconnect/web3-provider';
// import { RPC } from "../abis/constants";
import { getDefaultWallet } from '../helper/connect-wallet';
import { registerUser } from '../services/api';
// import * as utils from "../services/utils";

// Main login flow for Crypto.com DeFi Wallet with Wallet Extension
// The connector must be activated, then it exposes a provider
// that is used by the ethers Web3Provider constructor.
export const connect = async () => {
  const defaultWallet = getDefaultWallet();

  try {
    const net_id = localStorage.getItem('netId') || '56';
    const netId = parseInt(net_id);

    const provider = new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        56: 'https://bsc-dataseed1.binance.org/',
        97: 'https://data-seed-prebsc-1-s1.binance.org',
        25: 'https://evm-cronos.crypto.org',
      },
      chainId: netId,
    });
    await provider.enable();
    const web3 = new Web3(provider);
    localStorage.setItem('walletType', 'wallet-connect');

    if (!(parseInt(provider.chainId) === netId)) {
      window.alert('Switch your Wallet to blockchain network ' + netId);
    }
    // Subscribe to events that reload the app

    provider.on('chainChanged', chainId => {
      localStorage.setItem('netId', chainId);
      window.location.reload();
    });
    provider.on('accountsChanged', () => {
      window.location.reload();
    });

    const accounts = await web3.eth.getAccounts();
    registerUser(accounts[0])
      .then(res => {
        console.log('ok');
      })
      .catch(err => {
        console.log(err);
      });

    return {
      ...defaultWallet,
      walletProviderName: 'wallet-connect',
      address: (await web3.eth.getAccounts())[0],
      wcProvider: provider,
      web3: web3,
      connected: true,
      networkId: netId,
    };
  } catch (e) {
    console.log(e);
    return defaultWallet;
  }
};
