import React from 'react';
import { Button, Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import FooterLogo from '../assets/images/white_logo.png';

const menuItems = [
  // { label: 'Features', href: 'https://liquidus.finance/#features' },
  // { label: 'Premium', href: 'https://liquidus.finance/#premium' },
  // { label: 'Roadmap', href: 'https://liquidus.finance/#stages' },
  // { label: 'Tokenomics', href: 'https://liquidus.finance/#tokenomics' },
  // { label: 'Team', href: 'https://liquidus.finance/#team' },
  // { label: 'Litepaper', href: '/#' },
  { label: 'Terms', href: 'https://docs.liquidus.finance/about-liquidus/legal' },
  { label: 'Migration', href: 'https://farm.liquidus.finance/migrate' },
];

const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 16">
    <path
      fill="currentColor"
      d="M6.191 16c7.43 0 11.493-6.155 11.493-11.492 0-.175-.004-.349-.012-.523a8.209 8.209 0 002.015-2.091 8.046 8.046 0 01-2.319.636A4.052 4.052 0 0019.144.296c-.793.47-1.66.802-2.565.98A4.04 4.04 0 009.696 4.96 11.47 11.47 0 011.37.739 4.025 4.025 0 00.823 2.77c0 1.401.713 2.638 1.798 3.362a4.021 4.021 0 01-1.83-.505v.051a4.04 4.04 0 003.24 3.96 4.073 4.073 0 01-1.824.069 4.041 4.041 0 003.773 2.805 8.1 8.1 0 01-5.017 1.729c-.325 0-.647-.018-.963-.056A11.433 11.433 0 006.192 16"
    ></path>
  </svg>
);

const TelegramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 16">
    <path
      fill="currentColor"
      d="M1.186 6.968l11.527-4.749c1.138-.495 4.996-2.078 4.996-2.078s1.781-.692 1.633.99c-.05.692-.446 3.116-.841 5.738l-1.237 7.767s-.099 1.138-.94 1.336c-.841.197-2.226-.693-2.473-.891-.198-.148-3.711-2.374-4.997-3.463-.346-.297-.742-.89.05-1.583a189.5 189.5 0 005.194-4.947c.594-.593 1.187-1.979-1.286-.297l-6.976 4.7s-.791.495-2.275.049C2.077 9.095.345 8.502.345 8.502s-1.187-.742.841-1.534z"
    ></path>
  </svg>
);

const MediumIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 25 16">
    <path
      fill="currentColor"
      d="M13.905 8c0 3.866-3.113 7-6.953 7C3.112 15 0 11.867 0 8s3.113-7 6.952-7c3.84 0 6.953 3.134 6.953 7zm7.627 0c0 3.639-1.557 6.589-3.477 6.589-1.919 0-3.476-2.951-3.476-6.589 0-3.639 1.557-6.589 3.476-6.589 1.92 0 3.477 2.95 3.477 6.589zm3.119 0c0 3.261-.547 5.904-1.223 5.904-.675 0-1.222-2.644-1.222-5.904s.547-5.904 1.223-5.904c.675 0 1.222 2.643 1.222 5.904z"
    ></path>
  </svg>
);

const EmailIcon = () => <div className="mail-icon" />;

const socialLinks = [
  { href: 'https://twitter.com/LiquidusFinance', label: 'Twitter', icon: <TwitterIcon /> },
  { href: 'https://t.me/liquidusfinance', label: 'Telegram', icon: <TelegramIcon /> },
  { href: 'https://medium.com/@liquidus', label: 'Medium', icon: <MediumIcon /> },
  { href: 'mailto:team@liquidus.finance', label: 'Email', icon: <EmailIcon /> },
];

export default function Footer(props) {
  return (
    <div className="footer">
      <Grid container>
        <Grid item xs={12} px={{ xs: 2, lg: 10 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={FooterLogo} alt="logo" className="logo" />
            </Grid>
            <Grid item xs={12} md={8} justify="flex-start" sx={{ display: 'flex', justifyContent: {lg: 'right', md: 'right', sm: 'center', xs: 'center'} }}>
              <List>
                <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                  {menuItems.map((item, index) => (
                    <ListItem key={index} sx={{ justifyContent: 'center' }}>
                      <Link
                        href={item.href}
                        underline="none"
                        sx={{
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '500',
                          fontSize: {xs: 16, md: 11, lg:16}
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.label}
                      </Link>
                    </ListItem>
                  ))}
                </Stack>
              </List>
            </Grid>
            <Grid item xs={12} md={2}  sx={{display: 'flex', justifyContent: 'center'}}>
              <Button
                variant="contained"
                component="a"
                href="https://liquidus.finance/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#263254',
                  color: '#fff',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '600',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#fff',
                    color: '#263254',
                  },
                  borderRadius: 2,
                }}
              >
                Visit Website
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ borderBottom: '1px solid gray', m: '20px 0px' }}></Grid>
        <Grid item xs={12} px={{ xs: 5, sm: 10 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: 12,
                color: 'gray',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '500',
                p: { xs: '20px 0', sm: 0 },
              }}
            >
              2023 Liquidus DeFi Technology Systems Ltd, DIFC, Dubai All Rights Reserved
            </Typography>

            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center">
              <Stack direction="row" sx={{ order: { xs: 2, md: 1 } }}>
                {socialLinks.map((link, index) => (
                  <ListItem key={index} className="social-item">
                    <Link
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`View our ${link.label} profile`}
                      title={`View our ${link.label} profile`}
                    >
                      {link.icon}
                    </Link>
                  </ListItem>
                ))}
              </Stack>
              <Typography
                sx={{
                  fontSize: 12,
                  color: 'gray',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '500',
                  order: { xs: 1, md: 2 },
                }}
              >
                Join our community
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
