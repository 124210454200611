import React, { useEffect, useState, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../assets/images/liquidus_logo.png';
import CircleArrowDown from '../assets/images/circle-arrow-down.svg';
import CircleArrowRight from '../assets/images/circle-arrow-right.svg';
import InvertedLogo from '../assets/images/logo_inverted.png';
import DetailIcon from '../assets/images/arrow.png';
import MintNFTPopup from '../components/MintNFTPopup';
import FarmNFTPopup from '../components/FarmNFTPopup';
import WalletsPopup from '../components/WalletsPopop';
import SettingPopup from '../components/SettingPopup';
import MobileDrawer from '../components/MobileDrawer';
import DropMenu from '../components/DropMenu';
import {
  readableNumber,
  parseBNumber,
  formatDate,
  calRemainDate,
  makeBNumber,
} from '../services/utils';
import {
  NFT_LIST,
  // CRONOS_NFT_LIST,
  // POSEIDON_NFT_LIST,
  // EARLY_ADOPTER_NFT_LIST,
} from '../abis/constants';
import Spinner from '../components/Spinner';
import { getBNBPrice, getLIQPrice } from '../services/api';
import { COMPOUNDING_PERIOD } from '../services/config';
import WithdrawNFTPopup from '../components/WithdrawNFTPopup';
import { actions } from '../redux/common.redux';
import BuyLiqPopup from '../components/BuyLiqPopup';
import { getWallet } from '../helper/connect-wallet';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import Footer from '../components/Footer';
import LiquidusLogo from '../assets/images/LIQ_New 3.png';
import BnbLogo from '../assets/images/bitmap_logo.png';
import BusdLogo from '../assets/images/busd.svg';
import UsdcLogo from '../assets/images/usdc.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NFTDetail = props => {
  const { id: nft_id } = useParams();
  const nftID = parseInt(nft_id);
  const refreshCount = useSelector(state => state.common.refreshCount);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [web3Client, setWeb3Client] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [nft, setNft] = useState(null);
  const [dropId, setDropId] = useState(1);
  const [mintPopup, setMintPopup] = useState(false);
  const [farmPopup, setFarmPopup] = useState(false);
  const [openWithdrawPopup, setOpenWithdrawPopup] = useState(false);
  const [liqAmount, setLiqAmount] = useState(0);
  const [token2ndAmount, setToken2ndAmount] = useState(0);
  const [editon, setEditon] = useState(0);
  const [mintedAmount, setMintedAmount] = useState(0);
  const [apr, setApr] = useState(0);
  const [apy, setApy] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [startID, setStartID] = useState(0);
  const [endID, setEndID] = useState(0);
  const [stakedNFTIds, setStakedNFTIds] = useState([]);
  const [depositedNFTNumber, setDepositedNFTNumber] = useState(0);
  const [liqEarned, setLiqEarned] = useState(0);
  const [liqEarnedDol, setLiqEarnedDol] = useState(0);
  const [stakeContract, setStakeContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingHarvest, setLoadingHarvest] = useState(false);
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const [netId, setNetId] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSettingPopup, setOpenSettingPopup] = useState(false);

  const decimals = 18;

  useEffect(() => {
    if (nftID > 11 && nftID < 15) {
      const temp = NFT_LIST.find(x => x.nftID === nftID);
      setNft(temp);
    } 
    // else if (nftID < 7) {
    //   const temp = POSEIDON_NFT_LIST.find(x => x.nftID === nftID);
    //   setNft(temp);
    // } else if (nftID === 7) {
    //   const temp = EARLY_ADOPTER_NFT_LIST.find(x => x.nftID === nftID);
    //   setNft(temp);
    // } else if (nftID < 11) {
    //   const temp = CRONOS_NFT_LIST.find(x => x.nftID === nftID);
    //   setNft(temp);
    // }
  }, [nftID]);

  const getNFTInfo = useCallback(async () => {
    const { web3, address, networkId } = await getWallet();
    setWeb3Client(web3);
    setUserAccount(address);
    setNetId(networkId);

    try {
      if (!web3?.eth) return;
      if (!nft) return;

      const bnbPrice = await getBNBPrice();
      const liqPrice = await getLIQPrice();

      const nftContract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
      const token2nd_contract = new web3.eth.Contract(nft.token2ndAbi, nft.token2ndContract);
      const decimal_token2nd = await token2nd_contract.methods.decimals().call();

      const nftInfo = await nftContract.methods.NftInfo(nft.nftID).call();
      const liq = parseBNumber(nftInfo.priceInLIQ, decimals);
      setLiqAmount(liq);
      const token2nd =
        nft.token2nd === 'BNB'
          ? parseBNumber(nftInfo.priceInBNB, decimal_token2nd)
          : parseBNumber(nftInfo.priceIn2ndToken, decimal_token2nd);
      setToken2ndAmount(token2nd);
      setEditon(nftInfo.maximumAmount);
      setMintedAmount(nftInfo.mintedAmount);

      /* APR calculation */
      const sumPrice = liq * liqPrice + token2nd * (nft.token2nd === 'BNB' ? bnbPrice : 1);
      setMintPrice(sumPrice);
      const nftStakingContract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
      setStakeContract(nftStakingContract);

      //todo
      const annualReward = nft.liqRewards
        ? makeBNumber(nft.liqRewards, decimals)
        : await nftStakingContract.methods.annualReward().call();
      const totalReward = parseBNumber(annualReward, decimals);
      setTotalRewards(totalReward);
      const totalRewardPrice = totalReward * liqPrice;
      setTotalPrice(totalRewardPrice);
      const anualPercentReward = (totalRewardPrice * 100) / sumPrice;
      setApr(readableNumber(anualPercentReward));
      const anualPercentYield =
        (1 + anualPercentReward / 100 / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1;
      setApy(readableNumber(anualPercentYield * 100));

      /* TokenID range calculation */
      setStartID(parseInt(nft.nftID) * 1000000 + 1);
      setEndID(parseInt(nft.nftID) * 1000000 + parseInt(nftInfo.maximumAmount));

      /* Tokens of owner */
      // const tokens = await nftContract.methods.tokensOfHolder(account).call();

      const depositedNFTs = await nftContract.methods.balanceOf(nft.nftStaking).call();
      setDepositedNFTNumber(depositedNFTs);

      /* NFT deposited */
      const stakedTokens = address
        ? await nftStakingContract.methods.getStakedNftIDs(address).call()
        : null;
      setStakedNFTIds(stakedTokens);
      const pendingReward = address
        ? await nftStakingContract.methods.pendingReward(address).call()
        : null;
      const liq_earned = parseBNumber(pendingReward, decimals);
      setLiqEarned(readableNumber(liq_earned));
      setLiqEarnedDol(readableNumber(liq_earned * liqPrice));
    } catch (err) {
      console.log(err);
    }
  }, [nft]);

  useEffect(() => {
    getNFTInfo();
  }, [getNFTInfo, refreshCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      getNFTInfo();
    }, 10000);

    return () => clearInterval(interval);
  }, [getNFTInfo]);

  const handleHarvest = async () => {
    setLoadingHarvest(true);
    try {
      await stakeContract.methods.harvest(userAccount).send({ 
        from: userAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null, 
      });
      dispatch(actions.changeRefresh());
      setLoadingHarvest(false);
    } catch (err) {
      console.log(err);
      setLoadingHarvest(false);
    }
  };

  if (!nft) return null;
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" p={{ xs: 2, md: 5 }}>
        <Grid item className="pb-3">
          <a href="http://liquidus.finance">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
              <DropMenu netId={netId} />
            </Grid>

            <Grid item style={{ position: 'relative' }}>
              {userAccount ? (
                <>
                  <button
                    className="btn-round text-btn flex-center"
                    onClick={() => setOpenSettingPopup(true)}
                  >
                    {userAccount &&
                      `${userAccount.slice(0, 6)}...${userAccount.slice(
                        userAccount.length - 4,
                        userAccount.length,
                      )}`}{' '}
                    &nbsp;
                    <ChevronDownIcon style={{ fontSize: 24, color: '#181F2B' }} />
                  </button>
                  {openSettingPopup && <SettingPopup setOpenPopup={setOpenSettingPopup} />}
                </>
              ) : (
                <button
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '600',
                    borderRadius: 10,
                  }}
                  className="btn-round text-btn"
                  onClick={() => setOpenWalletPopup(true)}
                >
                  Connect Wallet
                </button>
              )}
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'none' }, mt: 1 }}>
              <MenuIcon onClick={() => setDrawerOpen(true)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="detail-container" mt={5}>
        <Grid container>
          <Grid item xs={12} md={4} lg={6} className="box-padding detail-image-box">
            <a href={nft?.fullImage} target="_blank" rel="noopener noreferrer">
              <div
                style={{ backgroundImage: `url(${nft?.smallImage})` }}
                className="nft-detail-img"
              />
            </a>
          </Grid>
          <Grid item xs={12} md={8} lg={6} className="box-padding pt-6 nft-detail">
            <Typography
              sx={{
                fontSize: 16,
                color: 'rgba(17, 36, 85, 1)',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '400',
              }}
            >
              {`${mintedAmount}/${editon} limited edition NFT`}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Playfair Display',
                fontWeight: '400',
                fontSize: { xs: 35, md: 50 },
                color: 'rgba(17, 36, 85, 1)',
              }}
            >
              {nft?.name}
            </Typography>

            <Typography
              sx={{
                fontSize: 12,
                color: '#9AA6CF',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '400',
              }}
            >
              {`Ends ${formatDate(nft.endDate)} (${calRemainDate(nft.endDate)})`}
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                color: 'rgba(17, 36, 85, 1)',
                pt: 2,
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '400',
              }}
            >
              Price to mint
            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ md: 'center' }}
              mt={1}
              spacing={{ xs: 5 }}
            >
              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" alignItems="center">
                      <img src={LiquidusLogo} alt="" height={30} />
                      <Typography
                        sx={{
                          fontSize: 14,
                          pl: 1,
                          color: '#9AA6CF',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '400',
                        }}
                      >
                        LIQ Price
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {liqAmount > 0 ? liqAmount + 'LIQ' : '-'}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <img src={LiquidusLogo} alt="" height={30} />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#9AA6CF',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '400',
                        }}
                      >
                        LIQ Price
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '700',
                          color: 'rgba(17, 36, 85, 1)',
                        }}
                      >
                        {liqAmount > 0 ? liqAmount + 'LIQ' : '-'}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" alignItems="center">
                      <img
                        src={
                          nft.token2nd === 'BNB'
                            ? BnbLogo
                            : nft.token2nd === 'BUSD'
                            ? BusdLogo
                            : nft.token2nd === 'USDC'
                            ? UsdcLogo
                            : null
                        }
                        alt=""
                        height={30}
                      />
                      <Typography
                        sx={{
                          fontSize: 14,
                          pl: 1,
                          color: '#9AA6CF',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '400',
                        }}
                      >
                        BUSD Price
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {token2ndAmount > 0 ? `${token2ndAmount} ${nft?.token2nd}` : '-'}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <img
                      src={
                        nft.token2nd === 'BNB'
                          ? BnbLogo
                          : nft.token2nd === 'BUSD'
                          ? BusdLogo
                          : nft.token2nd === 'USDC'
                          ? UsdcLogo
                          : null
                      }
                      alt=""
                      height={30}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#9AA6CF',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '400',
                        }}
                      >
                        BUSD Price
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '700',
                          color: 'rgba(17, 36, 85, 1)',
                        }}
                      >
                        {token2ndAmount > 0 ? `${token2ndAmount} ${nft?.token2nd}` : '-'}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      USD
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,

                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {token2ndAmount > 0 ? `$${readableNumber(mintPrice)}` : '-'}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#9AA6CF',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '400',
                        }}
                      >
                        USD
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: '700',
                          color: 'rgba(17, 36, 85, 1)',
                        }}
                      >
                        {token2ndAmount > 0 ? `$${readableNumber(mintPrice)}` : '-'}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3 }} />

            <Typography
              sx={{
                fontSize: 16,
                color: 'rgba(17, 36, 85, 1)',
                pt: 2,
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '400',
              }}
            >
              Total Rewards
            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ md: 'center' }}
              mt={1}
              spacing={{ xs: 5 }}
            >
              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      APY
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {apy}%
                    </Typography>
                  </Stack>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      APY
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {apy}%
                    </Typography>
                  </Box>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      LIQ
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {liqAmount > 0 ? liqAmount + 'LIQ' : '-'}
                    </Typography>
                  </Stack>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      LIQ
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      {liqAmount > 0 ? liqAmount + 'LIQ' : '-'}
                    </Typography>
                  </Box>
                )}
              </Stack>

              <Stack direction="row" spacing={1}>
                {isSmallScreen ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      USD
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      ${readableNumber(totalPrice)}
                    </Typography>
                  </Stack>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#9AA6CF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '400',
                      }}
                    >
                      USD
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        color: 'rgba(17, 36, 85, 1)',
                      }}
                    >
                      ${readableNumber(totalPrice)}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Stack>

            <Grid container justifyContent="center" spacing={3} mt={{ xs: 5, md: 10 }}>
              <Grid item xs={6}>
                <button
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '600',
                  }}
                  className="btn-border-lg"
                  onClick={() => setFarmPopup(true)}
                >
                  Farm NFT
                </button>
              </Grid>
              <Grid item xs={6}>
                <button
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '600',
                  }}
                  className="btn-round-lg"
                  onClick={() => setMintPopup(true)}
                >
                  Mint artwork
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} p={{ xs: 2, md: 0 }}>
          <Grid item xs={12} mb={5}>
            <Typography
              sx={{
                fontSize: 35,
                color: 'rgba(17, 36, 85, 1)',
                fontFamily: 'Playfair Display',
                fontWeight: '500',
              }}
            >
              NFT Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <Accordion
              elevation={0}
              sx={{
                p: '20px 0px',
                borderTop: '1px solid #e3e8ef',
                // borderBottom: '1px solid #e3e8ef',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setDropId(1)}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontFamily: 'Playfair Display',
                    fontWeight: '700',
                    color: 'rgba(17, 36, 85, 1)',
                  }}
                >
                  Description
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: 'rgba(17, 36, 85, 1)',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '400',
                  }}
                >
                  Liquidus NFTs are limited editions. They can be purchased (minted) by paying one
                  or more tokens. This will happen automatically when you confirm the Mint
                  transaction. Each Liquidus NFT can be used to farm LIQ rewards over a set period
                  of time. After the end of the farm, rewards stop. You can withdraw your NFT
                  anytime and send it to other addresses or sell it. An official Liquidus
                  marketplace is under development.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              sx={{
                p: '20px 0px',
                borderTop: '1px solid #e3e8ef',
                // borderBottom: '1px solid #e3e8ef',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setDropId(2)}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontFamily: 'Playfair Display',
                    fontWeight: '700',
                    color: 'rgba(17, 36, 85, 1)',
                  }}
                >
                  Creator
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {dropId === 2 ? (
                  <img src={CircleArrowDown} alt="" className="close-icon" />
                ) : (
                  <img src={CircleArrowRight} alt="" className="close-icon" />
                )}
                {dropId === 2 && (
                  <div className="flex-start">
                    <img src={InvertedLogo} alt="" className="liqlogo-box" />
                    <h3>Liquidus Original</h3>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              sx={{
                p: '20px 0px',
                borderTop: '1px solid #e3e8ef',
                // borderBottom: '1px solid #e3e8ef',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setDropId(3)}>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontFamily: 'Playfair Display',
                    fontWeight: '700',
                    color: 'rgba(17, 36, 85, 1)',
                  }}
                >
                  Additional information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {dropId === 3 ? (
                  <img src={CircleArrowDown} alt="" className="close-icon" />
                ) : (
                  <img src={CircleArrowRight} alt="" className="close-icon" />
                )}
                {dropId === 3 && (
                  <div>
                    <p
                      style={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                      }}
                      className="mb-2"
                    >
                      Read more here:
                    </p>
                    <a
                      href="https://docs.liquidus.finance/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '600',
                      }}
                    >
                      docs.liquidus.finance
                    </a>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              spacing={2}
              border="1px solid rgba(160, 178, 201, 0.45)"
              sx={{ p: 2, borderRadius: 2 }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'rgba(17, 36, 85, 0.5)',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '400',
                  }}
                >
                  LIQ EARNED
                </Typography>
                <span
                  style={{
                    fontSize: 20,
                    fontFamily: 'Playfair Display',
                    fontWeight: '700',
                    color: 'rgba(17, 36, 85, 1)',
                  }}
                >
                  {liqEarned}
                </span>
                <span
                  style={{
                    fontSize: 14,
                    color: '#9AA6CF',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '400',
                  }}
                >
                  = ${liqEarnedDol}
                </span>
              </Box>

              <Button
                disableElevation
                onClick={() => handleHarvest()}
                variant="contained"
                style={{backgroundColor: 'rgba(17, 36, 85, 1)'}}
                sx={{
                  textTransform: 'none',
                  color: '#fff',
                  minWidth: 250,
                  borderRadius: 3,
                  boxShadow: 'none',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '600',
                }}
              >
                {loadingHarvest ? (
                  <Spinner color="primary" />
                ) : (
                  <span
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '600',
                    }}
                  >
                    Harvest
                  </span>
                )}
              </Button>
            </Stack>
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
              border="1px solid rgba(160, 178, 201, 0.45)"
              sx={{ p: 2, borderRadius: 2, mt: 2 }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'rgba(17, 36, 85, 0.5)',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '400',
                  }}
                >
                  NFT DEPOSITED
                </Typography>
                {stakedNFTIds &&
                  stakedNFTIds.length > 0 &&
                  stakedNFTIds.map((item, idx) => {
                    return (
                      <div className="id-box" key={idx}>
                        <h4>ID {item}</h4>
                        <span
                          onClick={() => setOpenWithdrawPopup(true)}
                          style={{ cursor: 'pointer' }}
                        >
                          -
                        </span>
                      </div>
                    );
                  })}
                {(!stakedNFTIds || stakedNFTIds.length === 0) && (
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: 'Playfair Display',
                      fontWeight: '700',
                      color: 'rgba(17, 36, 85, 1)',
                    }}
                  >
                    There is no deposited NFT
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: 12,
                    color: '#9AA6CF',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '400',
                  }}
                >
                  Token ID range of this farm: {`${startID} - ${endID}`}
                </Typography>
              </Box>

              <Box className="text-normal text-gray">
                <a
                  href={`${nft.netUrl}/address/${nft.nftContract}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <label
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '400',
                      color: '#112455',
                      textDecorationLine: 'underline',
                      textUnderlinePosition: 'from-font',
                      cursor: 'pointer'
                    }}
                  >
                    View Contract
                  </label>
                  <span>
                    <img
                      src={DetailIcon}
                      alt=""
                      className="detail-icon"
                      style={{ width: 20, height: 20, position: 'absolute' }}
                    />
                  </span>
                </a>
              </Box>

              <Button
                disableElevation
                onClick={() => setFarmPopup(true)}
                variant="contained"
                fullWidth
                style={{backgroundColor: '#17e7d6'}}
                sx={{
                  textTransform: 'none',
                  color: 'rgba(17, 36, 85, 1)',
                  borderRadius: 3,
                  boxShadow: 'none',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '600',
                }}
              >
                Add NFT
              </Button>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#9AA6CF',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '400',
                }}
              >
                Number of NFTs Deposited
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '700',
                  color: 'rgba(17, 36, 85, 1)',
                }}
              >
                {depositedNFTNumber}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#9AA6CF',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '400',
                }}
              >
                Yearly Rewards per NFT
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '700',
                  color: 'rgba(17, 36, 83, 1)',
                }}
              >
                {totalRewards} LIQ
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#9AA6CF',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '400',
                }}
              >
                APR
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '700',
                  color: 'rgba(17, 36, 85, 1)',
                }}
              >
                {apr}%
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#9AA6CF',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '400',
                }}
              >
                End Date
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '700',
                  color: 'rgba(17, 36, 85, 1)',
                }}
              >
                {`${formatDate(nft.endDate)}`}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Footer />

      {mintPopup && (
        <MintNFTPopup
          setOpenPopup={setMintPopup}
          setFarmPopup={setFarmPopup}
          setOpenWalletPopup={setOpenWalletPopup}
          setOpenBuyPopup={setOpenBuyPopup}
          web3={web3Client}
          userAccount={userAccount}
          nft={nft}
          setLoading={setLoading}
        />
      )}
      {farmPopup && (
        <FarmNFTPopup
          setOpenPopup={setFarmPopup}
          setOpenWalletPopup={setOpenWalletPopup}
          web3={web3Client}
          userAccount={userAccount}
          nft={nft}
          setLoading={setLoading}
        />
      )}
      {openWithdrawPopup && (
        <WithdrawNFTPopup
          setOpenPopup={setOpenWithdrawPopup}
          web3={web3Client}
          userAccount={userAccount}
          nft={nft}
          setLoading={setLoading}
        />
      )}
      {openWalletPopup && <WalletsPopup setOpenPopup={setOpenWalletPopup} />}
      {openBuyPopup && (
        <BuyLiqPopup
          setOpenPopup={setOpenBuyPopup}
          setOpenWalletPopup={setOpenWalletPopup}
          web3={web3Client}
          userAccount={userAccount}
        />
      )}
      {loading && <Spinner shape="ellipsis" setLoading={setLoading} />}
      <MobileDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} netId={netId} />
    </>
  );
};

export default NFTDetail;
