// wallet-metamask.ts

// Injected wallet
// Works with MetaMask in browser or in in-app browser
import Web3 from 'web3';
import { NETWORK_INFO } from '../abis/constants';
import { getDefaultWallet } from '../helper/connect-wallet';
// import * as utils from "../services/utils";

// One feature of MetaMask is that the Dapp developer
// can programmatically
// change the network that the browser
// extension is connected to.
// This feature is implemented below,
// to automatically set - up Cronos

export const switchNetwork = async netId => {
  /* eslint-disable */
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + netId.toString(16) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        const tx = await window.ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: NETWORK_INFO[netId],
          })
          .catch();
      }
    }
    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const networkId = Number(chainId);
    if (networkId !== netId) {
      //Cancel case
      localStorage.setItem('netId', networkId);
    }
    if (tx) {
      console.log(tx);
    }
  }
};

// Main login flow for injected wallet like MetaMask
export const connect = async () => {
  const defaultWallet = getDefaultWallet();
  const net_id = localStorage.getItem('netId') || '56';
  const netId = parseInt(net_id);

  try {
    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const networkId = Number(chainId);

    if (!(networkId === netId)) {
      await switchNetwork(netId);
    }
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const web3 = new Web3(window.ethereum);
    localStorage.setItem('walletType', 'metamask');

    window.ethereum.on('chainChanged', chainId => {
      localStorage.setItem('netId', chainId);
      window.location.reload();
    });
    window.ethereum.on('accountsChanged', () => {
      window.location.reload();
    });
    // window.ethereum.on("disconnect", utils.reloadApp);

    return {
      ...defaultWallet,
      walletProviderName: 'metamask',
      address: accounts[0],
      connected: true,
      web3: web3,
      networkId: networkId,
    };
  } catch (e) {
    console.log(e);
    return defaultWallet;
  }
};
