import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Typography, Button, Tab, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TokenItem from './TokenItem';
import NFTItem from './NFTItem';
import {
  BSC_CONTRACT_LIST,
  CRONOS_CONTRACT_LIST,
  NFT_LIST,
  CRONOS_NFT_LIST,
  ETH_CONTRACT_LIST,
  MATIC_CONTRACT_LIST,
  NETWORK_INFO,
} from '../abis/constants';
import HackenLogo from '../assets/images/hacken-io-logo-vector.svg';
import LiquidusInvertLogo from '../assets/images/logo_inverted.png';
import BridgeGroup from './BridgeGroup';
import { getLIQPrice } from '../services/api';
import { ReactComponent as LIQSVG } from '../assets/images/LIQ_New.svg';
import { TabContext, TabList } from '@mui/lab';
import { useCookies } from 'react-cookie';

const farmDataList = {
  56: BSC_CONTRACT_LIST,
  137: MATIC_CONTRACT_LIST,
  1: ETH_CONTRACT_LIST,
  25: CRONOS_CONTRACT_LIST,
};

export default function TokensPanel(props) {
  const {
    web3,
    account,
    setSelectedContract,
    setSelectedKey,
    setLoading,
    setOpenStakePopup,
    setOpenBridgePopup,
    setOpenBuyPopup,
    netId,
  } = props;
  const panelList = useRef(null);
  const [farmList, setFarmList] = useState([]);
  const [nftList, setNftList] = useState([]);
  const [liqPrice, setLiqPrice] = useState(0);
  const [tabValue, setTabValue] = useState('active');
  const [showEndedFarms, setShowEndedFarms] = useState(false);
  const networkInfo = NETWORK_INFO[netId]?.[0];
  const showNFTs = netId === 56; // || netId === 25; (cronos netID=25 temporarily disabled)

  const [cookies, setCookie] = useCookies(['migration']);

  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
    // setShowEndedFarms(prev => !prev);
  };

  const filteredFarmList = useMemo(() => {
    if (showEndedFarms) {
      return farmList.filter(item => item.rewardPerBlock === 0);
    } else {
      return farmList.filter(item => item.rewardPerBlock !== 0);
    }
  }, [farmList, showEndedFarms]);

  const updateFarmWithBlocks = useCallback(
    async farmData => {
      const rewardPerBlocksPromises = [];
      for (const contract of farmData) {
        const farmContract = new web3.eth.Contract(contract.abi, contract.address);
        const rewardPerBlockCall = farmContract.methods.rewardPerBlock().call();
        rewardPerBlocksPromises.push(rewardPerBlockCall);
      }
      const data = await Promise.all(rewardPerBlocksPromises);
      const _farmList = farmData.map((item, index) => ({
        ...item,
        rewardPerBlock: Number(data[index]),
        showDetail: false,
        approved: false,
      }));
      setFarmList(_farmList);
    },
    [web3],
  );

  useEffect(() => {
    if (window.location.search.split('=')[1] === 'finished') {
      setTabValue(window.location.search.split('=')[1]);      
      setShowEndedFarms(prev => prev);
    }
  }, []);

  useEffect(() => {
    // const net_id = localStorage.getItem('netId');
    // const netId = net_id ? parseInt(net_id) : 56;
    if (netId) {
      updateFarmWithBlocks(farmDataList[netId]);
      const nfts = netId === 56 ? [...NFT_LIST] : netId === 25 ? [...CRONOS_NFT_LIST] : [];
      setNftList([...nfts]);
    }
  }, [netId, updateFarmWithBlocks]);

  useEffect(() => {
    async function get_liq_price() {
      const liqCost = await getLIQPrice();
      setLiqPrice(liqCost || 0);
    }
    get_liq_price();
  }, []);

  useEffect(() => {
    if (panelList.current) {
      panelList.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showEndedFarms]);

  const handleStake = (key, contract) => {
    setSelectedKey(key);
    setSelectedContract(contract);
    setOpenStakePopup(true);
  };

  const openInNewTab = url => {
    setTabValue('active')
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Box sx={{ p:  '0 50px' , mt: 5 }}>
      <Box sx={{ p: {xs: 0, lg: '0 60px'} }}>
        {!cookies?.migration && (
          <Grid
            container
            alignItems="center"
            sx={{
              p: 5,
              backgroundColor: '#effdfc',
              border: '1px solid #17e7d6',
              borderRadius: 2,
              position: 'relative',
            }}
          >
            <Grid item xs={12} sm={8} md={10}>
              <Typography
                sx={{
                  fontSize: { xs: 16, sm: 20 },
                  color: 'rgba(17, 36, 85, 1)',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '600',
                }}
              >
                LIQ 2.0 Token Migration
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 10, sm: 14 },
                  color: 'rgba(17, 36, 85, 1)',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '400',
                }}
              >
                Liquidus is undergoing a token migration. Migrate your Classic LIQ now and receive
                New LIQ. Migration is only open for 12 months until 9th November 2024.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
            <Button
  component="a"
  href="https://farm.liquidus.finance/migrate"
  target="_blank"
  rel="noreferrer"
  variant="contained"
  fullWidth
  sx={{
    mt: { xs: 2, sm: 0 },
    textTransform: 'none',
    backgroundColor: '#17e7d6',
    color: 'rgba(17, 36, 85, 1)',
    fontFamily: 'Plus Jakarta Sans', // fontFamily set here
    fontWeight: 600, // fontWeight set to 600, removed the duplicate
    '&:hover': {
      bgcolor: 'transparent',
    },
    boxShadow: 'none',
    borderRadius: 2,
  }}
>
  Migrate Now
</Button>

            </Grid>
            <CloseIcon
              sx={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}
              onClick={() =>
                setCookie('migration', true, {
                  path: '/',
                })
              }
            />
          </Grid>
        )}

        <Grid container alignItems="center" sx={{ p: '50px 20px' }} rowSpacing={5}>
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'start' } }}>
            <Typography
              sx={{
                fontSize: 35,
                color: 'rgba(17, 36, 85, 1)',
                fontFamily: 'Playfair Display',
                fontWeight: '400',
              }}
            >
              LIQ Farming
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
            <LIQSVG />
            <Typography
              sx={{
                fontSize: 16,
                color: '#A0B2C9',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '500',
              }}
            >
              LIQ Price
              <strong
                style={{
                  color: 'rgba(17, 36, 85, 1)',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '800',
                }}
              >
                {' '}
                {`$ ${parseFloat(liqPrice).toFixed(2)}`}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: {xs:'center', md:'flex-end'} }}>
            <TabContext value={tabValue}>
              <TabList
                onChange={tabHandleChange}
                indicatorColor="transparent"
                sx={{
                  backgroundColor: '#F2F4F7',
                  p: '8px 10px',
                  textTransform: 'none',
                  borderRadius: 2,
                  color: '#112455',
                  '& .MuiTab-root': {
                    textTransform: 'none', // Prevent text capitalization for all tabs
                    color: 'rgba(17, 36, 85, 0.3)',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '700',
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'white',
                    borderRadius: 2,
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '700',
                    borderBottom: 'none',
                    color: '#112455',
                  },
                }}
              >
                <Tab label="Active Farms" value="active" style={{ color: tabValue === 'active' ? '#112455' : 'rgba(17, 36, 85, 0.3)', minWidth: 100 }} />
                <Tab
                  label="Finished Farms"
                  value="finished"
                  style={{ color: tabValue === 'finished' ? '#112455' : 'rgba(17, 36, 85, 0.3)', minWidth: 100 }}
                  onClick={() => openInNewTab('https://classic-farm.liquidus.finance/')}
                />
              </TabList>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: 'none' }}>
        <Grid item className="heading-box">
          <h3 className="heading">Yield Farming</h3>
          <h6 className="sub-heading">
            Stake LIQ or LIQ-{networkInfo?.nativeCurrency.symbol} LP tokens to earn.
          </h6>
        </Grid>
        <BridgeGroup setOpenBridgePopup={setOpenBridgePopup} setOpenBuyPopup={setOpenBuyPopup} />
        <div className="flex-center mt-4" style={{ marginBottom: '-25px' }}>
          <div
            className="btn-normal mr-2 flex"
            style={{ borderRadius: '100px', paddingLeft: 12, paddingRight: 22, cursor: 'default' }}
          >
            {/* <div className='flex'> */}
            <img src={LiquidusInvertLogo} alt="logo" style={{ width: 30, height: 30 }} />
            <h6 className="coin-value">{`LIQ $ ${parseFloat(liqPrice).toFixed(2)}`}</h6>
            {/* </div> */}
          </div>
        </div>
      </Box>
      <Grid className="content" sx={{ mt: { xs: 0, md: 5 }}}>
        <Grid item  ref={panelList}>
          {filteredFarmList.map((item, idx) => {
            return (
              <TokenItem
                key={idx}
                contract={item}
                web3={web3}
                handleStake={handleStake}
                code={idx}
                userAccount={account}
                netId={netId}
                setLoading={setLoading}
              />
            );
          })}
        </Grid>
        <Box className="flex" sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
          <a
            href="https://hacken.io/wp-content/uploads/2021/12/Liquidus_20122021SCAudit_Report.pdf"
            target={'_blank'}
            rel="noreferrer"
          >
            <Stack direction="row" spacing={1} className="audited-by">
              <Typography>Audited by</Typography>
              <img src={HackenLogo} alt="" />
            </Stack>
          </a>
        </Box>
      </Grid>
      {showNFTs && (
        <Grid className="nft-section">
          <Box className="nft-container">
            <h3
              style={{
                fontFamily: 'Playfair Display',
                fontWeight: '400',
              }}
            >
              The New Liquidus NFTs
            </h3>
            <h6
              style={{
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '400',
              }}
            >
              Better Rewards. New Perks. Coming Soon. Images are just placeholders.
            </h6>
            <Grid container spacing={3}>
              {nftList.map((item, idx) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={idx}>
                    <NFTItem nft={item} web3={web3} userAccount={account} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      )}
    </Box>
  );
}
