import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Box } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../assets/images/liquidus_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/Footer';
import WalletsPopup from '../components/WalletsPopop';
import { EARLY_ADOPTER_NFT_LIST } from '../abis/constants';
import SettingPopup from '../components/SettingPopup';
import { actions } from '../redux/wallet.redux';
import Spinner from '../components/Spinner';
import NFTItem from '../components/NFTItem';
import { getWallet } from '../helper/connect-wallet';

export default function EarlyAdopter(props) {
  const dispatch = useDispatch();
  const refreshCnt = useSelector(state => state.common.refreshCount);
  const wallet = useSelector(state => state.wallet.wallet);
  const [web3Client, setWeb3Client] = useState(null);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [userAccount, setUserAccount] = useState('');
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [openSettingPopup, setOpenSettingPopup] = useState(false);
  const [nftList, setNftList] = useState([]);
  const [loading, setLoading] = useState(false);

  const connectBlockchain = useCallback(async () => {
    const newWallet = await getWallet();
    dispatch(actions.updateWalletAction(newWallet));
  }, [dispatch]);

  useEffect(() => {
    connectBlockchain();
  }, [connectBlockchain, refreshCnt]);

  useEffect(() => {
    const { web3, address } = wallet;
    setWeb3Client(web3);
    setUserAccount(address);
  }, [wallet]);

  useEffect(() => {
    setNftList([...EARLY_ADOPTER_NFT_LIST]);
  }, []);

  return (
    <Box className="root">
      <div className="bg-polygon" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="header"
      >
        <Grid item>
          <a href="http://liquidus.finance">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {/* <Grid item>
                            <DropMenu changeNet={changeNet}/>
                        </Grid> */}
            <Grid item style={{ position: 'relative' }}>
              {userAccount ? (
                <>
                  <button
                    className="btn-round text-btn flex-center"
                    onClick={() => setOpenSettingPopup(true)}
                  >
                    {userAccount &&
                      `${userAccount.slice(0, 6)}...${userAccount.slice(
                        userAccount.length - 4,
                        userAccount.length,
                      )}`}{' '}
                    &nbsp;
                    <ChevronDownIcon style={{ fontSize: 24, color: '#181F2B' }} />
                  </button>
                  {openSettingPopup && (
                    <SettingPopup
                      setOpenPopup={setOpenSettingPopup}
                      setUserAccount={setUserAccount}
                      currentProvider={currentProvider}
                      setCurrentProvider={setCurrentProvider}
                    />
                  )}
                </>
              ) : (
                <button className="btn-round text-btn" onClick={() => setOpenWalletPopup(true)}>
                  Connect Wallet
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="main-wrapper">
        <Grid className="poseidon-nft-section">
          <Box className="nft-container">
            <h3>Mint & Farm Liquidus NFTs</h3>
            <h6>Earn LIQ tokens at high APYs by minting and farming official Liquidus NFTs.</h6>
            <Grid container spacing={3}>
              {nftList.map((item, idx) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={idx}>
                    <NFTItem nft={item} web3={web3Client} userAccount={userAccount} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
        <Footer />
      </Grid>
      {openWalletPopup && (
        <WalletsPopup
          setOpenPopup={setOpenWalletPopup}
          setUserAccount={setUserAccount}
          setCurrentProvider={setCurrentProvider}
        />
      )}

      {loading && <Spinner shape="ellipsis" setLoading={setLoading} />}
    </Box>
  );
}
