import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DetailIcon from '../assets/images/copy_icon.png';
import { makeBNumber, parseBNumber } from '../services/utils';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/common.redux';
import { TOKEN_ABI } from '../abis/constants';
import Spinner from './Spinner';
import useAnalyticsEventTracker from '../services/useAnalyticsEventTracker';

export default function StakeTokenPopup(props) {
  const { setOpenPopup, type, contract, web3, userAccount } = props;
  const dispatch = useDispatch();
  const gaEventTracker = useAnalyticsEventTracker('Token');
  // const [userAccount, setUserAccount] = useState('');
  const [balance, setBalance] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [stake, setStake] = useState('');
  const [farmContractData, setFarmContractData] = useState({});
  const [stakeContractData, setStakeContractData] = useState({});
  const [tokenID, setTokenID] = useState('');
  const [rewardTokenID, setRewardTokenID] = useState('');
  const [loadingStake, setLoadingStake] = useState(false);

  const getStakeInfo = useCallback(async () => {
    if (!web3) return;

    try {
      //Load accounts
      // const accounts = await web3?.eth?.getAccounts();
      // const account = accounts && accounts[0];
      // if (!account) return;
      // setUserAccount(account);

      const farmContract = new web3.eth.Contract(contract.abi, contract.address);
      setFarmContractData(farmContract);

      const tokenAddress =
        contract.type === 'LP'
          ? await farmContract.methods.lpToken().call()
          : await farmContract.methods.stakedToken().call();
      setTokenID(tokenAddress);

      const rewardToken =
        contract.type === 'LP' ? await farmContract.methods.reward().call() : tokenAddress;
      setRewardTokenID(rewardToken);

      const tokenContract = new web3.eth.Contract(TOKEN_ABI[contract.type], tokenAddress);
      setStakeContractData(tokenContract);

      const decimal = await tokenContract.methods.decimals().call();
      setDecimals(decimal);

      if (!userAccount) return;

      if (type === 'withdraw') {
        const userInfo = await farmContract.methods.userInfo(userAccount).call();
        const staked = parseBNumber(userInfo.amount, decimal);
        setBalance(staked);
      } else if (type === 'stake') {
        const balanceOf = await tokenContract.methods.balanceOf(userAccount).call();
        const userBalance = parseBNumber(balanceOf, decimal);
        setBalance(userBalance);
      }

      // setBalance(123.56)
    } catch (err) {
      console.log(err);
    }
  }, [web3, contract, type, userAccount]);

  useEffect(() => {
    getStakeInfo();
  }, [getStakeInfo]);

  const confirmAction = async () => {
    try {
      setLoadingStake(true);
      const stakeAmount = makeBNumber(stake, decimals);

      if (type === 'stake') {

        // Check if allowance is larger than stake amount
        if (await isApprovalRequired(stake)) {
          await approveTokenMax();
        }

        if (contract.type === 'LP') {
          await farmContractData?.methods
            ?.deposit(stakeAmount, userAccount)
            .send({ 
              from: userAccount,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null, 
            });
          gaEventTracker('Deposited');
        } else {
          await farmContractData?.methods?.deposit(stakeAmount).send({ 
            from: userAccount, 
            maxPriorityFeePerGas: null,
            maxFeePerGas: null, 
          });
          gaEventTracker('Deposited');
        }
      } else if (type === 'withdraw') {
        if (contract.vesting) {
          // todo we need to set vestingTime dynamically
          const vestingTimeInBigNumber = await farmContractData.methods.vestingTime().call();
          const vestingTime = Number(vestingTimeInBigNumber);
          // const vestingTime = contract.vesting * 2629746;
          const userInfo = await farmContractData.methods.userInfo(userAccount).call();
          const releaseTime = (parseInt(userInfo?.lastDepositedAt) + vestingTime) * 1000; // in milisecond
          if (releaseTime < new Date().getTime()) {
            if (contract.type === 'LP') {
              await farmContractData?.methods
                ?.withdraw(stakeAmount, userAccount)
                .send({ 
                  from: userAccount, 
                  maxPriorityFeePerGas: null,
                  maxFeePerGas: null, 
                });
              gaEventTracker('Withdrawn');
            } else {
              await farmContractData?.methods?.withdraw(stakeAmount).send({ 
                from: userAccount, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null, 
              });
              gaEventTracker('Withdrawn');
            }
          } else {
            alert("You can't withdraw");
          }
        } else {
          if (contract.type === 'LP') {
            await farmContractData?.methods
              ?.withdraw(stakeAmount, userAccount)
              .send({ 
                from: userAccount, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
            gaEventTracker('Withdrawn');
          } else {
            await farmContractData?.methods?.withdraw(stakeAmount).send({ 
              from: userAccount, 
              maxPriorityFeePerGas: null,
              maxFeePerGas: null, });
            gaEventTracker('Withdrawn');
          }
        }
      }
      // if (result.status) {
      dispatch(actions.changeRefresh());
      setOpenPopup(false);
      // }
      setLoadingStake(false);
    } catch (err) {
      setLoadingStake(false);
      console.log(err);
      alert('Failed to Staking');
    }
  };

  const isApprovalRequired = async (amount) => {
    const allowance = await stakeContractData.methods.allowance(userAccount, contract.address).call();
    const allowanceParsed = parseBNumber(allowance, decimals);
    return allowanceParsed < amount;
  };

  const approveTokenMax = async () => {
    const approve_amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
    await stakeContractData?.methods
      ?.approve(
        contract.address,
        approve_amount,
      )
      .send({ 
        from: userAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
  };

  const cancelAction = () => {
    setOpenPopup(false);
  };

  return (
    <div className="bg-overlay flex-center">
      <div className="popup-wrapper">
        <div className="popup-box">
          <div className="card-header flex-between">
            <h4>{`${type === 'stake' ? 'Stake' : 'Withdraw'} ${contract.type} Tokens`}</h4>
            <IconButton aria-label="close" onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="card-body">
            <div className="stake-box">
              <div className="flex-between" style={{ marginBottom: 22 }}>
                <h4 className="stake-label">Stake</h4>
                <h4 className="stake-label">
                  {`${type === 'stake' ? 'Balance: ' : 'Staked: '}${(
                    Math.floor(balance * 1000) / 1000
                  ).toFixed(3)}`}
                </h4>
              </div>
              <div className="flex-between">
                <div className="stake-value">
                  <input
                    type="number"
                    className="stake-form"
                    placeholder="0"
                    value={stake}
                    onChange={e => setStake(e.target.value)}
                  />
                </div>
                <div className='badge' onClick={() => setStake(balance.toString())}>
                    Max
                </div>

                <div className="stake-value text-right">{contract.linkName}</div>
              </div>
            </div>
            <div className="button-group flex-center">
              <button className="btn-stake btn-cancel" onClick={() => cancelAction()}>
                Cancel
              </button>
              <div className="btn-stake btn-confirm flex-center" onClick={() => confirmAction()}>
                {loadingStake ? <Spinner /> : <span>Confirm</span>}
              </div>
            </div>
          </div>
          { contract.name !== "LIQ - Single Token" &&
          
            <div className="card-footer flex-center">
              <a
                href={
                  contract.title === 'QuickSwap V2' ||
                  contract.title === 'Uniswap' ||
                  contract.name === 'LIQ - Single Token'
                    ? `${contract.link}`
                    : `${contract.link}${contract.type === 'LP' ? rewardTokenID : tokenID}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Get {contract.linkName} &nbsp;
                <span>
                  <img src={DetailIcon} alt="" className="detail-icon" />
                </span>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
