import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/common.redux';
import { useCallback } from 'react';
import Spinner from './Spinner';
import { getCurrentNFTToken } from '../services/utils';

export default function WithdrawNFTPopup(props) {
  const { setOpenPopup, web3, userAccount, nft } = props;
  const dispatch = useDispatch();
  const [minTokenID, setMinTokenID] = useState(null);
  const [stakeContract, setStakeContract] = useState(null);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  const initProcess = useCallback(async () => {
    if (!web3) return;
    try {
      /* Get staked tokenIDs in user wallet */
      const stake_contract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
      setStakeContract(stake_contract);

      const tokens = (await stake_contract?.methods?.getStakedNftIDs(userAccount)?.call()) || [];
      const min_token_id = getCurrentNFTToken(tokens, nft.nftID);
      setMinTokenID(min_token_id);
    } catch (err) {
      console.log(err);
    }
  }, [nft, userAccount, web3]);
  useEffect(() => {
    initProcess();
  }, [initProcess]);

  const handleWithdrawNFT = async () => {
    setLoadingWithdraw(true);
    try {
      await stakeContract.methods.withdraw(minTokenID, userAccount)
      .send({ 
        from: userAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null, 
      });
      dispatch(actions.changeRefresh());
      setLoadingWithdraw(false);
      setOpenPopup(false);
    } catch (err) {
      console.log(err);
      setLoadingWithdraw(false);
    }
  };

  return (
    <div className="bg-overlay flex-center">
      <div className="popup-wrapper">
        <div className="popup-box">
          <div className="card-header flex-between">
            <h4 style={{ fontWeight: 500 }}>Withdraw Liquidus NFT</h4>
            <IconButton aria-label="close" onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          {/* {minTokenID ? ( */}
          {/* <> */}
          <div className="card-body">
            <div className="pt-10">
              <div className="flex-center">
                <div className="nft-icon flex-center">
                  <img src={nft.smallImage} alt="" />
                </div>
                <div style={{ marginLeft: 16 }}>
                  <div>
                    <span className="mint-logo-label text-primary text-bold">LIQNFT</span>
                  </div>
                  <div className="text-bold">
                    <span style={{ fontSize: 14, color: '#9AA6CF' }}>TOKEN ID: </span>
                    <span style={{ fontSize: 14, color: '#17E7D6' }}>{minTokenID}</span>
                  </div>
                </div>
              </div>
              <div className="flex-center" style={{ paddingTop: 73 }}>
                <div
                  className="btn-stake btn-confirm flex-center"
                  style={{ width: 268 }}
                  onClick={() => handleWithdrawNFT()}
                >
                  {loadingWithdraw ? <Spinner /> : <span>Withdraw</span>}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer flex-center">
            <p style={{ fontWeight: 500, opacity: 0.8, fontSize: 14 }}>
              Please confirm the transaction in your wallet.
            </p>
          </div>
          {/* </> */}
          {/* ) */}
        </div>
      </div>
    </div>
  );
}
