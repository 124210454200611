import Logo from '../../assets/images/logo_new.svg';
import classic_liq from '../../assets/images/LIQ.svg';
import EthIcon from '../../assets/images/eth-icon.svg';
import CronosIcon from '../../assets/images/cronos.svg';
import BinanceIcon from '../../assets/images/bitmap_logo.png';
import MaticIcon from '../../assets/images/matic-icon.svg';
import new_liq from '../../assets/images/LIQ_New.svg';
import ArrowRightIcon from '../../assets/images/arrow-right.svg';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import DropMenu from '../../components/DropMenu';
import SettingPopup from '../../components/SettingPopup';
import WalletsPopup from '../../components/WalletsPopop';
import TokenMigrationModel from './TokenMigrationModel';
import { useDispatch, useSelector } from 'react-redux';
import { getWallet } from '../../helper/connect-wallet';
import { actions } from '../../redux/wallet.redux';
import { toast } from 'react-toastify';
import {
  CLASSIC_LIQ_CONTRACT,
  CLASSIC_LIQ_ETH_CONTRACT,
  CLASSIC_LIQ_POLYGON_CONTRACT,
  CLASSIC_LIQ_CRONOS_CONTRACT,
  V2_MIGRATE_LIQ_CONTRACT,
} from "../../abis/constants";
import { makeBNumber } from "../../services/utils";
import { getLIQPrice } from '../../services/api';

export default function TokenMigration() {
  const [web3Client, setWeb3Client] = useState(null);
  const [netId, setNetId] = useState(0);
  const [userAccount, setUserAccount] = useState();
  const [openSettingPopup, setOpenSettingPopup] = useState(false);
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [openTokenMigrationPopup, setOpenTokenMigrationPopup] = useState(false);
  const [coins, setCoins] = useState(0.0);
  const [rawBalance, setRawBalance] = useState(null);
  const [approvalRequired, setApprovalRequired] = useState(true);
  const [migrationProcess, setMigrationProcess] = useState(false);
  const [classicLiqPrice, setClassicLiqPrice] = useState(0.8);

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet.wallet);
  const refreshCount = useSelector((state) => state.common.refreshCount);

  const decimals = 18;

  const connectBlockchain = useCallback(async () => {
    const newWallet = await getWallet();
    dispatch(actions.updateWalletAction(newWallet));
  }, [dispatch]);

  useEffect(() => {
    connectBlockchain();
  }, [connectBlockchain, refreshCount]);

  useEffect(() => {
    const { web3, address, networkId } = wallet;
    setWeb3Client(web3);
    setUserAccount(address);
    setNetId(networkId);
  }, [wallet]);

  async function approveCoins() {
    let contractInfo = CLASSIC_LIQ_CONTRACT;
    const classicLiqContract = new web3Client.eth.Contract(
      contractInfo.tokenAbi,
      contractInfo.singleTokenAddress,
    );
    try {
      let approve_amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
      let approvalResponse = await classicLiqContract.methods
        .approve(V2_MIGRATE_LIQ_CONTRACT.singleTokenAddress, approve_amount)
        .send({
          from: userAccount,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });

      if (approvalResponse && approvalResponse?.status) {
        setApprovalRequired(false);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function migrateTokensToBSC(amount) {
    let contractInfo;
    if (netId === 56) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_CONTRACT.tokenAbi,
        CLASSIC_LIQ_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 1) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_ETH_CONTRACT.tokenAbi,
        CLASSIC_LIQ_ETH_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 25) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_CRONOS_CONTRACT.tokenAbi,
        CLASSIC_LIQ_CRONOS_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 137) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_POLYGON_CONTRACT.tokenAbi,
        CLASSIC_LIQ_POLYGON_CONTRACT.singleTokenAddress,
      );
    }
    try {
      let transferResponse = await contractInfo.methods
        .transfer('0xAf967C1A979D4600AffCE6BffBaeACFd165A1a2A', amount)
        .send({
          from: userAccount,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });

      if (transferResponse) {
        setMigrationProcess(true);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function migrateTokens() {
   
    if (netId !== 56) return migrateTokensToBSC(rawBalance);

    let contractInfo = V2_MIGRATE_LIQ_CONTRACT;
    const migrateLiqContract = new web3Client.eth.Contract(
      contractInfo.tokenAbi,
      contractInfo.singleTokenAddress,
    );
    
    try {
      let swapResponse = await migrateLiqContract.methods.deposit(rawBalance).send({
        from: userAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      if (swapResponse) {
        setMigrationProcess(true);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function maxBalance(input) {
    setMigrationProcess(false);
    let contractInfo;
    if (netId === 56) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_CONTRACT.tokenAbi,
        CLASSIC_LIQ_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 1) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_ETH_CONTRACT.tokenAbi,
        CLASSIC_LIQ_ETH_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 25) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_CRONOS_CONTRACT.tokenAbi,
        CLASSIC_LIQ_CRONOS_CONTRACT.singleTokenAddress,
      );
    } else if (netId === 137) {
      contractInfo = new web3Client.eth.Contract(
        CLASSIC_LIQ_POLYGON_CONTRACT.tokenAbi,
        CLASSIC_LIQ_POLYGON_CONTRACT.singleTokenAddress,
      );
    }
    try {
      let balance = await contractInfo.methods.balanceOf(userAccount).call();
      const { web3 } = wallet;
      let parsedBalance = parseFloat(web3.utils.fromWei(balance.toString(), 'ether')).toFixed(2);
      if(input === "max")  {
        setRawBalance(balance);
        setCoins(parsedBalance);
      }
      return parsedBalance;
    } catch (e) {
      console.log(e);
    }
  }

  const networkIcons = {
    1: {
      name: 'ETH',
      icon: EthIcon,
    },
    56: {
      name: 'BSC',
      icon: BinanceIcon,
    },
    25: {
      name: 'CRO',
      icon: CronosIcon,
    },
    137: {
      name: 'POL',
      icon: MaticIcon,
    },
  };

  useEffect(() => {
    const liqPrice = async () => {
      await getLIQPrice().then((res) => setClassicLiqPrice(res / 5));
      return;
    };

    liqPrice();
  }, []);

  async function checkAllowance() {
    let contractInfo = new web3Client.eth.Contract(
      CLASSIC_LIQ_CONTRACT.tokenAbi,
      CLASSIC_LIQ_CONTRACT.singleTokenAddress,
    );

    try {
      let allowanceInfo = await contractInfo.methods
        .allowance(userAccount, V2_MIGRATE_LIQ_CONTRACT.singleTokenAddress)
        .call();

      const inputCoinAmount = makeBNumber(coins, decimals);
      let decimalCoinValue = parseInt(inputCoinAmount.toString());
      let decimalValueofAllowance = parseInt(allowanceInfo.toString());
      if (decimalCoinValue > decimalValueofAllowance) setApprovalRequired(true);
      else setApprovalRequired(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function checkIfInputCoinsExceedsBalance() {
    const balanceAvailable = await maxBalance();
    if (parseInt(coins) > parseInt(balanceAvailable)) {
      setCoins(balanceAvailable);
      const rawCoinBalance = makeBNumber(balanceAvailable, decimals).toString();
      setRawBalance(rawCoinBalance);
      toast.error(`Coins are exceeding your max balance: ${balanceAvailable}`, {
        autoClose: 5000,
        theme: 'colored',
        position: 'top-center',
      });
      return true;
    }
    return false;
  }

  async function startMigrationProcess() {
    setMigrationProcess(false);
    const checkBalance = await checkIfInputCoinsExceedsBalance();
    if (checkBalance) return;

    if (netId === 56) {
      await checkAllowance();
    } else {
      setApprovalRequired(false);
    }

    userAccount
      ? coins !== 0
        ? setOpenTokenMigrationPopup(true)
        : toast.warn('Input some amount', {
            autoClose: 5000,
            theme: 'colored',
            position: 'top-center',
          })
      : toast.error('Please connect your wallet', {
          autoClose: 5000,
          theme: 'colored',
          position: 'top-center',
        });
  }

  function setBalance(e) {
    setMigrationProcess(false);
    const coinValue = e.target.value;
    setCoins(coinValue);
    const rawCoinBalance = makeBNumber(coinValue, decimals).toString();
    setRawBalance(rawCoinBalance);
  }

  return (
    <div id="token-migration">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="header"
      >
        <Grid item className="pb-3">
          <a href="http://liquidus.finance">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </Grid>
        <Grid item className="gird_mobile_layout">
          <Grid container spacing={2}>
            <Grid item className="dropdown">
              <DropMenu isBlack netId={netId} className="drop_inner" />
            </Grid>
            <Grid item style={{ position: 'relative' }} className="drop_down_btn">
              {userAccount ? (
                <>
                  <button
                    className="btn-round text-btn flex-center"
                    onClick={() => setOpenSettingPopup(true)}
                  >
                    {userAccount &&
                      `${userAccount.slice(0, 6)}...${userAccount.slice(
                        userAccount.length - 4,
                        userAccount.length,
                      )}`}{' '}
                    &nbsp;
                    <ChevronDownIcon style={{ fontSize: 24, color: '#181F2B' }} />
                  </button>
                  {openSettingPopup && <SettingPopup setOpenPopup={setOpenSettingPopup} />}
                </>
              ) : (
                <button className="btn-round text-btn" onClick={() => setOpenWalletPopup(true)}>
                  Connect Wallet
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <section className="token-migration-hero">
        <div className="background-picture-token-migration">
          <div className="hero-content">
            <div>
              <h4 className="vertical-text">Migrate your tokens</h4>
            </div>
            <div className="hero-headings">
              <h1 style={{ color: '#112455' }}>LIQ Token Migration</h1>
              <p>
                Liquidus is undergoing a token migration. Every LIQ holder of the initial Liquidus
                token, now referred to as “Classic LIQ” should migrate their tokens to the New LIQ
                token.
              </p>
              <div className="convertion-calculator">
                <div className="convertion-calculator-field">
                  <div className="coin-name-chip">
                    <img src={classic_liq} alt="" />
                    <p>Classic LIQ</p>
                    <div style={{ flex: 1 }} />
                    {netId !== 56 && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          justifySelf: 'end',
                        }}
                      >
                        <b>From {networkIcons[netId]?.name}</b>
                        <img
                          src={networkIcons[netId]?.icon}
                          alt=""
                          style={{
                            backgroundColor:
                              networkIcons[netId]?.name === 'CRO' ? 'black' : 'transparent',
                            borderRadius: networkIcons[netId]?.name === 'CRO' ? '50%' : 'initial',
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div className="convertion-calculator-field-text max-div">
                    <input
                      type="number"
                      value={migrationProcess ? 0 : coins}
                      placeholder="0.0"
                      onChange={(e) => {
                        setBalance(e);
                      }}
                      className="hide-arrows-number-field"
                      name="toconvert"
                      id="toconvert"
                    />
                    <button
                      onClick={() => maxBalance("max")}
                      className="max_btn"
                    >
                      MAX
                    </button>
                  </div>
                  <p>
                    =$
                    {migrationProcess
                      ? 0
                      : coins === 0
                      ? ""
                      : (coins * classicLiqPrice).toFixed(2)}
                  </p>
                </div>
                <span className="arrow-icon">
                  <img src={ArrowRightIcon} alt="" />
                </span>
                <div className="convertion-calculator-field">
                  <div className="coin-name-chip">
                    <img src={new_liq} alt="" />
                    <p>New LIQ</p>
                    <div style={{ flex: 1 }} />
                    {netId !== 56 && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          justifySelf: 'end',
                        }}
                      >
                        <b>To {networkIcons[56]?.name}</b>
                        <img src={networkIcons[56]?.icon} alt="" />
                      </span>
                    )}
                  </div>
                  <div className="convertion-calculator-field-text">
                    <input
                      type="text"
                      value={migrationProcess ? 0 : coins / 5.0}
                      disabled
                      name="converted"
                      id="converted"
                    />
                  </div>
                  <p style={{ opacity: 0 }}>
                  =$
                    {migrationProcess
                      ? 0
                      : ((coins / 1.0) * classicLiqPrice).toFixed(2)}
                  </p>
                </div>
                <button
                  className="primary-button-token-migration"
                  style={{
                    fontFamily: "'Playfair Display', serif",
                    color: '#112455',
                  }}
                  onClick={startMigrationProcess}
                >
                  Migrate Tokens
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <p>
          Liquidus is undergoing a token migration. Every LIQ holder of the initial Liquidus token,
          now referred to as “Classic LIQ” should migrate their tokens to the New LIQ token. The
          migration ratio is set to 5:1, which means for 5 Classic LIQ, you’ll receive 1 New LIQ.
          Liquidity for New LIQ was also added at a 5:1 ratio. At the time of adding liquidity, 1
          New LIQ was worth exactly 5 Classic LIQ.
        </p>
        <h2>
          Migrate your Classic LIQ tokens now by using the interface above. You
          will receive your New LIQ within one week from the time of your
          migration.
        </h2>
        <p>
          If you are holding Classic LIQ on another blockchain, such as Ethereum, Polygon or Cronos,
          read the article below to learn more about how to migrate your tokens.
        </p>
        <p>
          Migration is open for a period of <b>12 months</b> . Starting from 09th November, 2023.
        </p>
        <a href="https://blog.liquidus.finance/" rel="noreferrer" target="_blank">
          Read the Full Article here
        </a>
        {netId !== 56 && (
          <div className="migrate_cross">
            <h3>Cross Chain Migration Info</h3>
            <p>
              You are going to receive your New LIQ tokens on the Binance Smart Chain (BSC) network.
            </p>
            <p>
              You are NOT receiving your tokens immediately. There are specific dates on when the
              tokens on BSC get airdropped to everyone who migrated the tokens in the meantime. Make
              sure to read the latest announcements.
            </p>
          </div>
        )}
      </section>
      <hr />
      <footer className="token-migration-footer">
        <img src={Logo} alt="" />
        <p style={{ opacity: 0.6 }}>
          2023 Liquidus DeFi Technology Systems Ltd, DIFC, Dubai All Rights Reserved
        </p>
        <button onClick={() => setOpenWalletPopup(true)}>Connect Wallet</button>
      </footer>
      {openTokenMigrationPopup && (
        <TokenMigrationModel
          approveCoins={approveCoins}
          migrateTokens={migrateTokens}
          coins={coins}
          setOpenPopup={setOpenTokenMigrationPopup}
          netId={netId}
          approvalRequired={approvalRequired}
        />
      )}
      {openWalletPopup && <WalletsPopup setOpenPopup={setOpenWalletPopup} />}
    </div>
  );
}
