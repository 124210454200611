import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { Grid, Box, Typography, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Web3 from 'web3';
import moment from 'moment';
import WalletsPopup from '../../components/WalletsPopop';
import DropMenuPot from '../../components/DropMenuPot';
import Spinner from '../../components/Spinner';
import Logo from '../../assets/images/liquidus_logo_blue.png';
import PotLogo from '../../assets/images/pot-logo.png';
import Footer from '../../components/Footer';
import { actions } from '../../redux/wallet.redux';
import StandardTier from '../../assets/images/titan/Standard.png';
import SilverTier from '../../assets/images/titan/Silver.png';
import GoldTier from '../../assets/images/titan/Gold.png';
import TitanTier from '../../assets/images/titan/Titan.png';
import CopyIcon from '../../assets/images/copy-icon-plus.svg';
import WarningIcon from '../../assets/images/warning-circle.svg';
import WarningPurpleIcon from '../../assets/images/warning-circle-purple.svg';
import TickCircle from '../../assets/images/tick-circle.svg';
import UntickCircle from '../../assets/images/untick-circle.svg';
import { getDefaultWallet, getWallet } from '../../helper/connect-wallet';
import { getLIQPrice } from '../../services/api';
import { LIQ_POT, POT_ADDRESSES, POT_MULTIPLIER15X, POT_MULTIPLIER2X } from '../../services/config';
import { ButtonNormal } from '../../components/Button';
import { ShareItem } from './components/ShareItem';
import { parseBNumber } from '../../services/utils';
import { LIQ_HOLDING_CONTRACTS } from '../../abis/constants';

const TierIcons = [StandardTier, SilverTier, GoldTier, TitanTier];

export default function Pot(props) {
  const dispatch = useDispatch();
  const { readRemoteFile } = usePapaParse();
  const refreshCnt = useSelector(state => state.common.refreshCount);
  const wallet = useSelector(state => state.wallet.wallet);

  const [liqPrice, setLiqPrice] = useState(0);
  const [baseShare, setBaseShare] = useState(0);
  const [totalPot, setTotalPot] = useState(0);
  const [preOrders, setPreOrders] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const [daysPercent, setDaysPercent] = useState(0);
  const [liqBoost, setLiqBoost] = useState(0);
  const [tierMode, setTierMode] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [walletAddress, setWalletAddress] = useState('');
  const [netId, setNetId] = useState(0);
  const [web3Client, setWeb3Client] = useState(null);
  const [userAccount, setUserAccount] = useState('');
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [isWhiteListed, setIsWhitelisted] = useState(false);
  const [loading, setLoading] = useState(false);
  const showWalletConnect = !userAccount;
  const showSignup = userAccount && !isWhiteListed;
  const showStake = userAccount && isWhiteListed && liqBoost < 25;
  const showCheck = userAccount && isWhiteListed && liqBoost >= 25;

  const airDrop = useMemo(() => {
    const _factor = 1 + liqBoost / 100;
    const _airDrop = baseShare * _factor * multiplier;
    return _airDrop;
  }, [baseShare, liqBoost, multiplier]);

  const connectBlockchain = useCallback(async () => {
    const newWallet = await getWallet();
    dispatch(actions.updateWalletAction(newWallet));
  }, [dispatch]);

  const initProcess = useCallback(async () => {
    try {
      setLoading(true);
      const liq_price = await getLIQPrice();
      setLiqPrice(liq_price);
      readRemoteFile(LIQ_POT, {
        complete: results => {
          const _result = results.data;
          if (_result && _result.length > 7) {
            const _goal1Preoder = Number(_result[1][1]);
            const _goal1Liq = Number(_result[1][2]);
            const _goal2Preoder = Number(_result[2][1]);
            const _goal2Liq = Number(_result[2][2]);
            const _goal3Preoder = Number(_result[3][1]);
            const _goal3Liq = Number(_result[3][2]);
            const _goal4Preoder = Number(_result[4][1]);
            const _goal4Liq = Number(_result[4][2]);
            const _goal5Preoder = Number(_result[5][1]);
            const _goal5Liq = Number(_result[5][2]);
            const _goal6Preoder = Number(_result[6][1]);
            const _goal6Liq = Number(_result[6][2]);
            const _goal7Preoder = Number(_result[7][1]);
            const _goal7Liq = Number(_result[7][2]);
            const _endDate1 = _result[1][4];
            const _preOrders = Number(_result[2][4]);
            setPreOrders(_preOrders);
            var _totalPot = 0;
            if (_preOrders < _goal1Preoder) {
              _totalPot = _preOrders * 10;
            } else if (_preOrders < _goal2Preoder) {
              _totalPot = _preOrders * 10 + _goal1Liq;
            } else if (_preOrders < _goal3Preoder) {
              _totalPot = _preOrders * 10 + _goal2Liq;
            } else if (_preOrders < _goal4Preoder) {
              _totalPot = _preOrders * 10 + _goal3Liq;
            } else if (_preOrders < _goal5Preoder) {
              _totalPot = _preOrders * 10 + _goal4Liq;
            } else if (_preOrders < _goal6Preoder) {
              _totalPot = _preOrders * 10 + _goal5Liq;
            } else if (_preOrders < _goal7Preoder) {
              _totalPot = _preOrders * 10 + _goal6Liq;
            } else if (_preOrders > _goal7Preoder) {
              _totalPot = _preOrders * 10 + _goal7Liq;
            }
            setTotalPot(_totalPot);
            const _startDate = moment('10.10.2022', 'DD.MM.YYYY');
            const _endDate = moment(_endDate1, 'DD.MM.YYYY');
            const _today = moment().startOf('day');
            const _totalDiff = _endDate.diff(_startDate, 'days');
            const _daysLeft = _endDate.diff(_today, 'days');
            const _daysPast = _today.diff(_startDate, 'days');
            setDaysLeft(_daysLeft > 0 ? _daysLeft : 0);
            setDaysPercent((_daysPast * 100) / _totalDiff);
          }
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [readRemoteFile]);

  const getMultiplier = useCallback(
    address => {
      readRemoteFile(POT_MULTIPLIER15X, {
        complete: results => {
          const _data = results.data;
          var _existing = false;
          _data.length > 0 &&
            _data.forEach(item => {
              if (item[0].toLowerCase().includes(address.toLowerCase())) {
                _existing = true;
              }
            });
          if (_existing) {
            setMultiplier(1.5);
          }
          readRemoteFile(POT_MULTIPLIER2X, {
            complete: results => {
              const _data = results.data;
              var _existing = false;
              _data.length > 0 &&
                _data.forEach(item => {
                  if (item[0].toLowerCase().includes(address.toLowerCase())) {
                    _existing = true;
                  }
                });
              if (_existing) {
                setMultiplier(2);
              }
            },
          });
        },
      });
    },
    [readRemoteFile],
  );

  const getUserHoldings = useCallback(
    async address => {
      if (!web3Client) return;
      try {
        let allPromises = [];
        const contract = LIQ_HOLDING_CONTRACTS.find(item => item.netId === netId);
        const liqHoldingContract = new web3Client.eth.Contract(contract.abi, contract.address);
        allPromises.push(liqHoldingContract.methods.userHoldings(address).call());
        allPromises.push(liqHoldingContract.methods.feeStandard().call());
        allPromises.push(liqHoldingContract.methods.feeSilver().call());
        allPromises.push(liqHoldingContract.methods.feeGold().call());
        allPromises.push(liqHoldingContract.methods.feeTitan().call());
        allPromises.push(liqHoldingContract.methods.percentStandard().call());
        allPromises.push(liqHoldingContract.methods.percentSilver().call());
        allPromises.push(liqHoldingContract.methods.percentGold().call());
        allPromises.push(liqHoldingContract.methods.percentTitan().call());
        allPromises.push(liqHoldingContract.methods.silver().call());
        allPromises.push(liqHoldingContract.methods.gold().call());
        allPromises.push(liqHoldingContract.methods.titan().call());
        const result = await Promise.all(allPromises);
        const userHolding = parseBNumber(result[0], 18);
        var feeMode = 0;
        const _silver = Number(result[9]);
        const _gold = Number(result[10]);
        const _titan = Number(result[11]);
        if (userHolding < _silver) {
          feeMode = 0;
        } else if (userHolding < _gold) {
          feeMode = 1;
        } else if (userHolding < _titan) {
          feeMode = 2;
        } else {
          feeMode = 3;
        }
        const _liqBoost = Number(result[5 + feeMode]);
        setLiqBoost(_liqBoost);
        setTierMode(feeMode);
      } catch (err) {
        console.log(err);
      }
    },
    [web3Client, netId],
  );

  useEffect(() => {
    setIsWhitelisted(false);
    if (userAccount) {
      readRemoteFile(POT_ADDRESSES, {
        complete: results => {
          const _result = JSON.stringify(results.data);
          var _totalAddresses = results.data.length - 1;
          if (results.data[_totalAddresses][0] === '') {
            _totalAddresses = _totalAddresses - 1;
          }
          if (_result.toLowerCase().includes(userAccount.toLowerCase())) {
            setIsWhitelisted(true);
            setBaseShare(totalPot / _totalAddresses);
            getUserHoldings(userAccount);
            getMultiplier(userAccount);
          }
        },
      });
    }
  }, [userAccount, totalPot, readRemoteFile, getUserHoldings, getMultiplier]);

  useEffect(() => {
    if (Web3.utils.isAddress(walletAddress)) {
      setUserAccount(walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    initProcess();
  }, [initProcess]);

  useEffect(() => {
    connectBlockchain();
  }, [connectBlockchain, refreshCnt]);

  useEffect(() => {
    const { web3, address, networkId } = wallet;
    setWalletAddress('');
    setWeb3Client(web3);
    setUserAccount(address);
    setNetId(networkId);
  }, [wallet]);

  const disconnect = async () => {
    const walletType = localStorage.getItem('walletType');
    const netId = localStorage.getItem('netId');

    if (walletType === 'wallet-connect') {
      await wallet.wcProvider.disconnect();
      localStorage.clear();
      localStorage.setItem('netId', netId);
      window.location.reload();
    } else if (walletType === 'defi-wallet') {
      await wallet.wcConnector.deactivate();
      localStorage.clear();
      localStorage.setItem('netId', netId);
      window.location.reload();
    } else if (walletType === 'metamask') {
      localStorage.clear();
      localStorage.setItem('netId', netId);
      const defaultWallet = getDefaultWallet();
      dispatch(actions.updateWalletAction(defaultWallet));
    }
  };
  const handleChangeWalletAddress = e => {
    setWalletAddress(e.target.value);
  };

  const handleClearWallet = () => {
    disconnect();
    setWalletAddress('');
    setUserAccount('');
    setBaseShare(0);
    setLiqBoost(0);
    setMultiplier(1);
  };

  return (
    <Stack>
      <Box
        sx={{
          backgroundColor: 'white',
          minHeight: '100vh',
          paddingBottom: '40px',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: { xs: '35px 20px', sm: '35px 30px', md: '33px 60px', xl: ' 33px 130px' } }}
        >
          <Grid item className="pb-3">
            <a href="http://liquidus.finance">
              <img src={Logo} alt="logo" className="logo" />
            </a>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <a href="http://farm.liquidus.finance">
                  <div className="btn-green">Farm</div>
                </a>
              </Grid>
              <Grid item style={{ position: 'relative' }}>
                <div className="btn-label">LIQ ${liqPrice.toFixed(2)}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ padding: { xs: '0 20px', sm: '0 30px', md: '0 60px', xl: '0 130px' } }}>
          <Box className="pot-container">
            <h6>App pre-order giveaway</h6>
            <Grid container spacing={5}>
              <Grid item xs={12} md={5.5}>
                <img className="pot-logo" src={PotLogo} alt="pot-logo" />
              </Grid>
              <Grid
                item
                xs={12}
                md={6.5}
                sx={{ display: 'flex', alignItems: 'flex-end', zIndex: 1 }}
              >
                <Stack>
                  <Stack spacing="22px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 500,
                        lineHeight: '22px',
                        color: '#112455',
                      }}
                    >
                      Invite friends to pre-order the Liquidus app and we put 10 LIQ per new user
                      into the pot for everyone!
                    </Typography>
                    <Box borderRadius="10px" overflow="hidden" sx={{ background: '#F9F9FB' }}>
                      <Box
                        sx={{ width: `${daysPercent}%`, height: '6px', background: '#17E7D6' }}
                      />
                    </Box>
                    <Stack marginTop="20px" direction="row" spacing="50px">
                      <Stack spacing="7px">
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '29px',
                            color: '#112455',
                          }}
                        >
                          {totalPot} LIQ
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '18px',
                            lineHeight: '22px',
                            color: '#9AA6CF',
                          }}
                        >
                          Total pot
                        </Typography>
                      </Stack>
                      <Stack spacing="7px">
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '29px',
                            color: '#112455',
                          }}
                        >
                          {preOrders}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '18px',
                            lineHeight: '22px',
                            color: '#9AA6CF',
                          }}
                        >
                          Pre-orders
                        </Typography>
                      </Stack>
                      <Stack spacing="7px">
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '29px',
                            color: '#112455',
                          }}
                        >
                          {daysLeft}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '18px',
                            lineHeight: '22px',
                            color: '#9AA6CF',
                          }}
                        >
                          Days left
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack marginTop="40px" spacing="10px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#112455',
                      }}
                    >
                      Invite your friends to pre-order
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <div>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            fontSize: '12px',
                            color: '#112455',
                          }}
                        >
                          iPhone
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#112455',
                          }}
                        >
                          https://apps.apple.com/app/id1625544806
                        </Typography>
                      </div>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigator.clipboard.writeText('https://apps.apple.com/app/id1625544806')
                        }
                      >
                        <img src={CopyIcon} alt="" />
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          'https://play.google.com/store/apps/details?id=finance.liquidus.wallet',
                        )
                      }
                    >
                      <div>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            fontSize: '12px',
                            color: '#112455',
                          }}
                        >
                          Android
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#112455',
                          }}
                        >
                          https://play.google.com/store/apps/details?id=finance.liquidus.wallet
                        </Typography>
                      </div>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigator.clipboard.writeText(
                            'https://play.google.com/store/apps/details?id=finance.liquidus.wallet',
                          )
                        }
                      >
                        <img src={CopyIcon} alt="" />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={5.5}>
                <Stack
                  spacing="10px"
                  sx={{ backgroundColor: '#F9F9FB', borderRadius: '12px', padding: '18px' }}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <h6>Check your share</h6>
                    <DropMenuPot netId={netId} />
                  </Stack>
                  <Box marginTop="5px">
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        color: '#112455',
                      }}
                    >
                      Participants must hold at least 1000 LIQ in one of the Liquidus Farm staking
                      contracts on BNB Smart Chain.
                    </Typography>
                  </Box>
                  {userAccount ? (
                    <Stack
                      direction={{ lg: 'row', md: 'column' }}
                      alignItems="center"
                      spacing={3}
                      sx={{ padding: '16px 0' }}
                    >
                      {showStake && (
                        <a href="https://farm.liquidus.finance/">
                          <ButtonNormal label="Stake now" />
                        </a>
                      )}
                      {showSignup && (
                        <a href="https://cb6jnet5fpx.typeform.com/preorder-pot">
                          <ButtonNormal label="Sign up" />
                        </a>
                      )}
                      {showCheck && (
                        <img className="titan-img" src={TierIcons[tierMode]} alt="tier" />
                      )}
                      <Stack direction="row" alignItems="center" spacing="10px">
                        <Typography
                          sx={{
                            padding: { xs: '10px 0', md: '0 0' },
                            fontFamily: 'Montserrat',
                            fontSize: { xs: '12px', xl: '14px' },
                            fontWeight: 600,
                            lineHeight: '20px',
                            color: '#112455',
                          }}
                        >
                          {userAccount}
                        </Typography>
                        <CloseIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                            color: '#9AA6CF',
                            cursor: 'pointer',
                          }}
                          onClick={handleClearWallet}
                        />
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction={{ lg: 'row', md: 'column' }}
                      alignItems="center"
                      spacing={{ xs: '8px', lg: '20px' }}
                      sx={{ padding: '16px 0' }}
                    >
                      <ButtonNormal
                        label="Connect wallet"
                        onClick={() => setOpenWalletPopup(true)}
                      />
                      <div>
                        <Typography
                          sx={{ fontFamily: 'Montserrat', fontSize: '12px', color: '#9AA6CF' }}
                        >
                          or
                        </Typography>
                      </div>
                      <TextField
                        variant="outlined"
                        label="Enter wallet address"
                        size="small"
                        sx={{
                          width: { xs: '100%', lg: 'auto' },
                          flex: 1,
                          label: { color: '#9AA6CF', fontFamily: 'Montserrat' },
                          fieldset: { borderRadius: '8px', border: '1px solid #D7DCEC' },
                          input: { color: '#112455', fontFamily: 'Montserrat' },
                        }}
                        value={walletAddress}
                        onChange={handleChangeWalletAddress}
                      />
                    </Stack>
                  )}
                  {showStake && (
                    <Box marginTop="5px" sx={{ position: 'relative' }}>
                      <Typography
                        sx={{
                          paddingRight: '20px',
                          fontFamily: 'Montserrat',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '30px',
                          color: '#000000',
                        }}
                      >
                        Your wallet doesnt have 1000 Liq in a staking contract, please add more to
                        join the giveaway.
                      </Typography>
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '20px',
                          height: '20px',
                          top: 0,
                          right: 0,
                        }}
                      >
                        <img src={WarningIcon} alt="warning-circle" />
                      </Box>
                    </Box>
                  )}
                  {showSignup && (
                    <Box marginTop="5px" sx={{ position: 'relative' }}>
                      <Typography
                        sx={{
                          paddingRight: '20px',
                          fontFamily: 'Montserrat',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '30px',
                          color: '#000000',
                        }}
                      >
                        Your wallet hasn’t been added yet.
                        <br />
                        Sign up and we will add your address next Sunday.
                      </Typography>
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '20px',
                          height: '20px',
                          top: 0,
                          right: 0,
                        }}
                      >
                        <img src={WarningPurpleIcon} alt="warning-circle" />
                      </Box>
                    </Box>
                  )}
                  {(showWalletConnect || showCheck) && (
                    <Stack spacing="16px" sx={{ padding: '0 8px' }}>
                      <ShareItem label="Base share" value={`${baseShare.toFixed(2)} LIQ`} />
                      <ShareItem label="Premium boost" value={`${liqBoost}%`} />
                      <ShareItem label="Your multiplier" value={`${multiplier}X`} />
                      <ShareItem label="Your airdrop" value={`${airDrop.toFixed(2)} LIQ`} />
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6.5} sx={{ display: 'flex' }}>
                <Stack spacing="8px" sx={{ padding: '18px 0' }}>
                  <h6>Stretch goals</h6>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        color: '#112455',
                      }}
                    >
                      Hit these targets as a community and we will give out bonus LIQ tokens to the
                      pot
                    </Typography>
                  </Box>
                  <Stack paddingTop="16px" spacing="16px">
                    <Grid container direction={{ xs: 'column', md: 'row' }}>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 1000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: '#112455',
                                  }}
                                >
                                  1k
                                </Typography>
                                <div>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '18px',
                                      fontWeight: 600,
                                      color: '#112455',
                                    }}
                                  >
                                    pre orders
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                5k LIQ Bonus
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 2000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: '#112455',
                                  }}
                                >
                                  2k
                                </Typography>
                                <div>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '18px',
                                      fontWeight: 600,
                                      color: '#112455',
                                    }}
                                  >
                                    pre orders
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                10k LIQ Bonus
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 5000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: '#112455',
                                  }}
                                >
                                  5k
                                </Typography>
                                <div>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '18px',
                                      fontWeight: 600,
                                      color: '#112455',
                                    }}
                                  >
                                    pre orders
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                20k LIQ Bonus
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container direction={{ xs: 'column', md: 'row' }}>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 10000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: '#112455',
                                  }}
                                >
                                  10k
                                </Typography>
                                <div>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '18px',
                                      fontWeight: 600,
                                      color: '#112455',
                                    }}
                                  >
                                    pre orders
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                40k LIQ Bonus
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 25000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    color: '#112455',
                                  }}
                                >
                                  25k
                                </Typography>
                                <div>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Montserrat',
                                      fontSize: '18px',
                                      fontWeight: 600,
                                      color: '#112455',
                                    }}
                                  >
                                    pre orders
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                100k LIQ Bonus
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item md={4}>
                        <Stack spacing="20px">
                          <Box sx={{ width: '40px', height: '40px' }}>
                            <img
                              src={preOrders >= 50000 ? TickCircle : UntickCircle}
                              alt="tick-circle"
                            />
                          </Box>
                          <Stack direction="row" spacing="18px">
                            <Box
                              sx={{ width: '2px', height: '63px', backgroundColor: '#D7DCEC' }}
                            />
                            <Stack>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '24px',
                                  fontWeight: 600,
                                  color: '#112455',
                                }}
                              >
                                More to come
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  color: '#112455',
                                }}
                              >
                                Excited?
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box sx={{ padding: { xs: '0 20px', md: '0 30px', xl: '0 100px' } }}>
        <Footer />
      </Box>
      {openWalletPopup && <WalletsPopup setOpenPopup={setOpenWalletPopup} />}

      {loading && <Spinner shape="ellipsis" setLoading={setLoading} />}
    </Stack>
  );
}
