import { default as BN } from 'bn.js';
import moment from 'moment';
import intervalToDuration from 'date-fns/intervalToDuration';

export const makeBNumber = (amount, decimal) => {
  const str = amount.toString();
  const isDecimal = str.includes('.');
  const decimalNumber = isDecimal ? str.split('.')[1].length : 0;
  let amountInt = parseInt(str.split('.').join(''));
  let decimalb = 10 ** (decimal - decimalNumber);
  if (decimalNumber > decimal) {
    amountInt = amountInt / 10 ** (decimalNumber - decimal);
    decimalb = 1;
  }

  const decimals = new BN(decimalb.toString());
  const bn = new BN(new BN(amountInt).mul(decimals));
  return bn;
};

export const parseBNumber = (amount, decimal) => {
  let decimalb = 10 ** decimal;
  const value = amount / decimalb;
  return value;
};

export const queryUrl = (url, params) => {
  const paths = [];
  for (const key of Object.keys(params)) {
    const atomStr = params[key] ? `${key}=${params[key]}` : `${key}`;
    paths.push(atomStr);
  }
  return `${url}?${paths.join('&')}`;
};

export const readableNumber = bigNum => {
  const str = bigNum.toString();
  if (str.includes('e+')) {
    const splits = str.split('e+');
    const temp = parseFloat(splits[0]).toFixed(2).toString();
    splits[0] = temp;
    return splits.join('e+');
  } else {
    return bigNum.toFixed(2);
  }
};

export const parseDateTime = timestamp => {
  const dateTime = new Date(timestamp);
  const y = dateTime.getFullYear();
  const m = ('00' + (dateTime.getMonth() + 1)).slice(-2);
  const d = ('00' + dateTime.getDate()).slice(-2);
  const hh = ('00' + dateTime.getHours()).slice(-2);
  const mm = ('00' + dateTime.getMonth()).slice(-2);
  const ss = ('00' + dateTime.getSeconds()).slice(-2);
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
};

export const formatDate = dateStr => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  if (!dateStr) return '';
  const dateTime = new Date(dateStr);
  const y = dateTime.getFullYear();
  const m = dateTime.getMonth();
  const d = dateTime.getDate();

  return `${monthName[m]}. ${d}, ${y}`;
};

export const calRemainDate = endDate => {
  const diff = intervalToDuration({
    start: new Date(),
    end: new Date(endDate),
  });

  let result = [];
  if (diff.years !== 0) result.push(`${diff.years} years`);
  if (diff.months !== 0) result.push(`${diff.months} months`);
  if (diff.days !== 0) result.push(`${diff.days} days`);

  return result.join(', ');
};

export const getCurrentNFTToken = (tokens, nftID) => {
  if (!tokens) return null;
  if (tokens.length === 0) return null;
  const currentTokens = tokens.filter(x => Math.floor(parseInt(x) / 1000000) === nftID);
  if (currentTokens && currentTokens.length > 0) {
    return Math.min(...currentTokens);
  } else {
    return null;
  }
};

export const getMintTime = endDate => {
  const date = endDate.split('-');
  const year = parseInt(date[0]);
  const month = parseInt(date[1]);
  const day = parseInt(date[2]);
  return Date.UTC(year - 1, month - 1, day, 18, 0, 0);
};

export const cutDecimals = (num, n) => {
  return Math.floor(num * 10 ** n) / 10 ** n;
};

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const reloadApp = () => {
  window.location.reload();
};

export const convertFromISODateWithFormat = (dateString, format) => {
  const date = moment(dateString, format).toDate();
  return date;
};
