import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Routes from '../src/routes';
import { GA_MEASUREMENT_ID } from './services/config';

ReactGA.initialize(GA_MEASUREMENT_ID);

class App extends Component {
  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}
export default App;
