import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Box, Typography, Button } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../assets/images/liquidus_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/wallet.redux';
import Footer from '../components/Footer';
import WalletsPopup from '../components/WalletsPopop';
import SettingPopup from '../components/SettingPopup';
import DropMenu from '../components/DropMenu';
import TokensPanel from '../components/TokensPanel';
import StakeTokenPopup from '../components/StakeTokenPopup';
import Spinner from '../components/Spinner';
import BridgePopup from '../components/BridgePopup';
import BuyLiqPopup from '../components/BuyLiqPopup';
import { getWallet } from '../helper/connect-wallet';
import CoinmarketPopup from '../components/CoinmarketPopup';
import BackgroundImage from '../assets/images/background1.png';
import MobileBackgroundImage from '../assets/images/Group8.png';
import Scroll from '../assets/images/scroll.png';
import MenuIcon from '@mui/icons-material/Menu';
import MobileDrawer from '../components/MobileDrawer';

export default function Home(props) {
  const tokensPanelRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const wallet = useSelector(state => state.wallet.wallet);
  const refreshCount = useSelector(state => state.common.refreshCount);

  const [netId, setNetId] = useState(0);
  const [web3Client, setWeb3Client] = useState(null);
  const [userAccount, setUserAccount] = useState('');
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [openSettingPopup, setOpenSettingPopup] = useState(false);
  const [openStakePopup, setOpenStakePopup] = useState(false);
  const [openBridgePopup, setOpenBridgePopup] = useState(false);
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const [openCmPopup, setOpenCmPopup] = useState(false);
  const [selectedKey, setSelectedKey] = useState('stake');
  const [selectedContract, setSelectedContract] = useState({});
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const connectBlockchain = useCallback(async () => {
    const newWallet = await getWallet();
    dispatch(actions.updateWalletAction(newWallet));
  }, [dispatch]);

  const handleScrollClick = () => {
    if (tokensPanelRef.current) {
      tokensPanelRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    connectBlockchain();
  }, [connectBlockchain, refreshCount]);

  useEffect(() => {
    const { web3, address, networkId } = wallet;
    setWeb3Client(web3);
    setUserAccount(address);    
    setNetId(networkId);
  }, [wallet]);

  useEffect(() => {
    const path = location.pathname;
    if (path === '/buyliq') {
      setOpenBuyPopup(true);
    }
    if (path === '/bridge') {
      setOpenBridgePopup(true);
    }
    if (path === '/cmcairdrop') {
      setOpenCmPopup(true);
    }
  }, [location.pathname]);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        className="root"
        sx={{
          backgroundImage: {
            xs: `url(${MobileBackgroundImage})`,
            md: `url(${BackgroundImage})`,
          },
          // backgroundColor: '#f3f4f6',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'relative',
          backgroundPositionY: {
            sm: '-10px'
          },
          // backgroundPositionY: '-20px'
        }}
      >
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" className="header">
            <Grid item className="pb-3">
              <a href="http://liquidus.finance">
                <img src={Logo} alt="logo" className="logo" />
              </a>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                  <DropMenu netId={netId} />
                </Grid>

                <Grid item style={{ position: 'relative' }}>
                  {userAccount ? (
                    <>
                      <button
                        className="btn-round text-btn flex-center"
                        onClick={() => setOpenSettingPopup(true)}
                      >
                        {userAccount &&
                          `${userAccount.slice(0, 6)}...${userAccount.slice(
                            userAccount.length - 4,
                            userAccount.length,
                          )}`}{' '}
                        &nbsp;
                        <ChevronDownIcon style={{ fontSize: 24, color: '#181F2B' }} />
                      </button>
                      {openSettingPopup && <SettingPopup setOpenPopup={setOpenSettingPopup} />}
                    </>
                  ) : (
                    <button
                      className="btn-round text-btn"
                      style={{
                        borderRadius: 10,
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '600',
                      }}
                      onClick={() => setOpenWalletPopup(true)}
                    >
                      Connect Wallet
                    </button>
                  )}
                </Grid>
                <Grid item sx={{ display: { xs: 'block', md: 'none' }, mt: 1 }}>
                  <MenuIcon onClick={() => setDrawerOpen(true)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: 2, sm: 10 },
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: '700',
              color: 'rgba(17, 36, 85, 1)',
            }}
          >
            Yield Farming
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Playfair Display',
              fontWeight: '500',
              fontSize: { xs: 45, sm: 75 },
              color: 'rgba(17, 36, 85, 1)',
            }}
          >
            Start earning
            <br /> passive income
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: '400',
              fontSize: { xs: 12, sm: 16 },
              color: 'rgba(17, 36, 85, 1)',
            }}
          >
            Stake LIQ tokens to earn. <br />
            Use the interface here or our app to do it in one click.
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#17E7D6',
              mt: 4,
              boxShadow: 'none',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              borderRadius: 2,
            }}
          >
            <a href="https://liquidus.app.link/dsmexkJD1tb" target="_blank" rel="noreferrer">
              <label
                style={{
                  color: '#112455',
                  fontSize: 13,
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '600',
                }}
              >
                Download app for free
              </label>
            </a>
          </Button>          
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <img
            src={Scroll}
            alt="scroll"
            style={{ position: 'absolute', bottom: 100, height: 70, cursor: 'pointer' }}
            onClick={handleScrollClick}
          />
        </Grid>
      </Grid>
        {/* <div className="bg-polygon" /> */}
        <Box ref={tokensPanelRef}>
          <TokensPanel
            web3={web3Client}
            account={userAccount}
            setSelectedKey={setSelectedKey}
            setSelectedContract={setSelectedContract}
            setOpenStakePopup={setOpenStakePopup}
            setOpenBridgePopup={setOpenBridgePopup}
            setOpenBuyPopup={setOpenBuyPopup}
            netId={netId}
            setLoading={setLoading}
          />
          <Footer />
        </Box>
        {openWalletPopup && <WalletsPopup setOpenPopup={setOpenWalletPopup} />}
        {openStakePopup && (
          <StakeTokenPopup
            type={selectedKey}
            contract={selectedContract}
            setOpenPopup={setOpenStakePopup}
            web3={web3Client}
            userAccount={userAccount}
            setLoading={setLoading}
          />
        )}
        {openBridgePopup && (
          <BridgePopup
            setOpenPopup={setOpenBridgePopup}
            setOpenWalletPopup={setOpenWalletPopup}
            web3={web3Client}
            userAccount={userAccount}
          />
        )}

        {openBuyPopup && (
          <BuyLiqPopup
            setOpenPopup={setOpenBuyPopup}
            setOpenWalletPopup={setOpenWalletPopup}
            web3={web3Client}
            userAccount={userAccount}
          />
        )}

        {openCmPopup && (
          <CoinmarketPopup
            setOpenPopup={setOpenCmPopup}
            web3Prop={web3Client}
            accountProp={userAccount}
          />
        )}

        {loading && <Spinner shape="ellipsis" setLoading={setLoading} />}
      <MobileDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} netId={netId} />
    </>
  );
}
