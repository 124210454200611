import React, { useEffect, useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
// import Spinner from './Spinner';
import SelectNet from './SelectNet';
import Chart from './Chart';
import DetailIcon from '../assets/images/copy_icon.png';
import ArrowRightIcon from '../assets/images/arrow-right.svg';
import ArrowDownIcon from '../assets/images/arrow-down.svg';
import BscIcon from '../assets/images/bitmap_logo.png';
import CronosIcon from '../assets/images/cronos-icon.svg';
import EthIcon from '../assets/images/eth-icon.svg';
import MaticIcon from '../assets/images/matic-icon.svg';
import LiqIcon from '../assets/images/liquidus_logo_round.png';
import CheckmarkIcon from '../assets/images/checkmark.svg';
import WarningIcon from '../assets/images/warning.png';
import CopyIcon from '../assets/images/copy-icon.svg';
import { SWAP_CONTRACTS } from '../abis/constants';
import { getBridgeSwapData, getLIQPrice, getTransferStatus } from '../services/api';
import { parseBNumber, readableNumber } from '../services/utils';
import { toast } from 'react-toastify';
import useAnalyticsEventTracker from '../services/useAnalyticsEventTracker';

export default function BridgePopup(props) {
  const { web3, setOpenPopup, userAccount, setOpenWalletPopup } = props;
  const gaEventTracker = useAnalyticsEventTracker('Bridge');

  const [step, setStep] = useState(0);
  const [fromNetId, setFromNetId] = useState(56);
  const [toNetId, setToNetId] = useState(56);
  const [minSwap, setMinSwap] = useState(0);
  const [maxSwap, setMaxSwap] = useState(0);
  const [feeRate, setFeeRate] = useState(0);
  const [minFee, setMinFee] = useState(0);
  const [maxFee, setMaxFee] = useState(0);
  const [liqPrice, setLiqPrice] = useState(0);
  const [swapAmount, setSwapAmount] = useState('');
  const [statusPercent, setStatusPercent] = useState(0);
  const [approveLoading, setApproveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isCopied0, setIsCopied0] = useState(false);
  const [isCopied1, setIsCopied1] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);
  const [isCopied3, setIsCopied3] = useState(false);

  const decimals = 18;
  let tmr = useRef();
  let tmr2 = useRef();
  let tmr3 = useRef();
  let errorTime = useRef();
  let tempCounter = useRef(0);
  let timer = useRef(0);
  let counter = useRef(0);
  let status = useRef(0);
  let txhash = useRef(null);

  const netInfo = {
    56: { name: 'BSC', label: 'Binance Smart Chain', image: BscIcon, netId: 56 },
    25: { name: 'CRONOS', label: 'Cronos', image: CronosIcon, netId: 25 },
    1: { name: 'ETH', label: 'Ethereum', image: EthIcon, netId: 1 },
    137: { name: 'MATIC', label: 'Matic', image: MaticIcon, netId: 137 },
  };

  const initProcess = useCallback(async () => {
    if (!web3) return;

    try {
      setLoading(true);
      const networkId = await web3?.eth?.net?.getId();
      setFromNetId(networkId);
      const to_netId = networkId === 56 ? 25 : 56;
      setToNetId(to_netId);

      const liq_price = await getLIQPrice();
      setLiqPrice(liq_price);
      await handleChainNet(networkId, to_netId);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [web3]);

  useEffect(() => {
    initProcess();

    return () => {
      clearInterval(tmr.current);
      clearTimeout(tmr2.current);
      clearTimeout(tmr3.current);
    };
  }, [initProcess]);

  const handleChainNet = async (from_netId, to_netId) => {
    setLoading(true);
    setToNetId(to_netId);
    const response = await getBridgeSwapData();
    const anyswap = response?.[from_netId];
    const token = Object.keys(anyswap).find(key => anyswap?.[key]?.anyToken?.name === 'Liquidus');
    const destChains = token ? anyswap[token]['destChains'] : [];

    setMinSwap(destChains?.[to_netId]?.['MinimumSwap'] || 0);
    setMaxSwap(destChains?.[to_netId]?.['MaximumSwap'] || 0);
    setMinFee(destChains?.[to_netId]?.['MinimumSwapFee'] || 0);
    setMaxFee(destChains?.[to_netId]?.['MaximumSwapFee'] || 0);
    setFeeRate(destChains?.[to_netId]?.['SwapFeeRatePerMillion'] || 0);

    setLoading(false);
  };

  const getFee = amount => {
    const amount_in = amount === '' ? 0 : parseFloat(amount);
    const fee_amount = (amount_in * feeRate) / 100;
    const fee =
      fee_amount < parseFloat(minFee)
        ? parseFloat(minFee)
        : fee_amount > parseFloat(maxFee)
        ? parseFloat(maxFee)
        : fee_amount;

    return {
      fee: fee,
      receive: amount_in - fee,
    };
  };

  const setChainNet = async () => {
    if (loading || approveLoading) return;
    if (!userAccount) return;

    if (fromNetId !== 56) {
      setStep(5);
      return;
    }

    try {
      const contractInfo = SWAP_CONTRACTS?.[fromNetId];
      const tokenContract = new web3.eth.Contract(
        contractInfo.tokenAbi,
        contractInfo.singleTokenAddress,
      );

      if (fromNetId === 56) {
        setApproveLoading(true);
        const allowance = await tokenContract.methods
          .allowance(userAccount, contractInfo.address)
          .call();
        // setApproved(allowance > 0);

        if (allowance === '0') {
          await tokenContract.methods
            .approve(
              contractInfo.address,
              '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
            )
            .send({ from: userAccount });
          setApproveLoading(false);
          // setApproved(true);
        } else {
          setApproveLoading(false);
        }
      }

      const balanceOf = await tokenContract.methods.balanceOf(userAccount).call();
      const userBalance = parseBNumber(balanceOf, decimals);
      setBalance(userBalance);
      setStep(1);
    } catch (err) {
      console.log(err);
      setApproveLoading(false);
    }
  };

  const transfer = async () => {
    if (!userAccount) return;
    if (swapAmount === '') return;
    if (parseFloat(swapAmount) > balance) return;
    txhash.current = null;

    try {
      if (
        parseFloat(swapAmount) < parseFloat(minSwap) ||
        parseFloat(swapAmount) > parseFloat(maxSwap)
      ) {
        toast.error(
          `Invalid amount. Minimum Swap amount is ${minSwap} and Maximum Swap amount is ${maxSwap}.`,
          { autoClose: 3000, theme: 'colored' },
        );
        return;
      }
      setStep(2);
      const contractInfo = SWAP_CONTRACTS?.[fromNetId];
      const swapContract = new web3.eth.Contract(contractInfo.abi, contractInfo.address);

      tmr.current = setInterval(async () => {
        timer.current += 1;

        if (status.current > 0) {
          if (counter.current >= 24 && status.current === 1) {
            counter.current = 24;
            setStatusPercent(counter.current);
          } else if (counter.current < 25 && status.current === 2) {
            counter.current = 25;
            setStatusPercent(counter.current);
          } else if (counter.current >= 49 && status.current === 2) {
            counter.current = 49;
            setStatusPercent(counter.current);
          } else if (counter.current < 50 && status.current === 8) {
            counter.current = 50;
            setStatusPercent(counter.current);
          } else if (counter.current >= 74 && status.current === 8) {
            counter.current = 74;
            setStatusPercent(counter.current);
          } else if (counter.current < 75 && status.current === 9) {
            counter.current = 75;
            setStatusPercent(counter.current);
          } else if (counter.current >= 99 && status.current === 9) {
            counter.current = 99;
            setStatusPercent(counter.current);
          } else if (counter.current < 100 && status.current === 10) {
            counter.current = 100;
            setStatusPercent(counter.current);
          } else if (counter.current >= 100 && status.current === 10) {
            counter.current = 100;
            setStatusPercent(counter.current);
          } else {
            if (status.current > 1) {
              if (timer.current % 3 === 0) {
                counter.current += 1;
              }
            } else {
              counter.current += 1;
            }
            setStatusPercent(counter.current);
          }

          if (txhash.current && status.current > 1 && timer.current % 10 === 0) {
            const response = await getTransferStatus(txhash.current);
            const confirmation = response?.info?.confirmations;
            if (response?.msg === 'Error') {
              if (tempCounter.current === 0) {
                errorTime.current = new Date().getTime();
              }
              if (new Date().getTime() - errorTime.current > 270000) {
                // 4 min 30 sec
                setIsError(true);
              } else {
                setIsError(false);
              }
              tempCounter.current += 1;
            } else {
              setIsError(false);
            }
            status.current = response?.info?.status || 2;
            if (confirmation > 14) status.current = 10;
            console.log('status: ', response?.info?.status);
          }

          if (status.current === 10) {
            tmr2.current = setTimeout(() => {
              setStep(3);
              tmr3.current = setTimeout(() => {
                setStep(4);
                status.current = -1;
              }, 1500);
            }, 1000);
            clearInterval(tmr.current);
          } else if (status.current === 14) {
            setStep(6);
            clearInterval(tmr.current);
          }
        }
      }, 1000);

      if (fromNetId === 56) {
        swapContract.methods
          .anySwapOutUnderlying(
            contractInfo.tokenAddress,
            userAccount,
            web3.utils.toWei(
              typeof swapAmount == 'number' ? swapAmount.toFixed(18) : swapAmount,
              'ether',
            ),
            toNetId,
          )
          .send({ from: userAccount })
          .on('transactionHash', hash => {
            txhash.current = hash;
            if (status.current === 0) {
              status.current = 1;
            }
          })
          .on('receipt', () => {
            if (status.current < 2) {
              status.current = 2;
            }
            gaEventTracker('successfull');
          })
          .on('error', () => {
            setStep(1);
          });
      } else {
        swapContract.methods
          .anySwapOut(
            contractInfo.tokenAddress,
            userAccount,
            web3.utils.toWei(
              typeof swapAmount == 'number' ? swapAmount.toFixed(18) : swapAmount,
              'ether',
            ),
            toNetId,
          )
          .send({ from: userAccount })
          .on('transactionHash', hash => {
            txhash.current = hash;
            if (status.current === 0) {
              status.current = 1;
            }
          })
          .on('receipt', () => {
            if (status.current < 2) {
              status.current = 2;
            }
            gaEventTracker('successfull');
          })
          .on('error', () => {
            setStep(1);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changeFarmNet = async () => {
    setOpenPopup(false);
    localStorage.setItem('netId', toNetId);
    window.location.reload();
  };

  const connectWallet = () => {
    setOpenWalletPopup(true);
    setOpenPopup(false);
  };

  const copyTextToClipboard = async text => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const hanldeCopyClipboard = (copyText, index) => {
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        if (index === 0) {
          setIsCopied0(true);
          setTimeout(() => {
            setIsCopied0(false);
          }, 1500);
        } else if (index === 1) {
          setIsCopied1(true);
          setTimeout(() => {
            setIsCopied1(false);
          }, 1500);
        } else if (index === 2) {
          setIsCopied2(true);
          setTimeout(() => {
            setIsCopied2(false);
          }, 1500);
        } else if (index === 3) {
          setIsCopied3(true);
          setTimeout(() => {
            setIsCopied3(false);
          }, 1500);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="bg-overlay flex-center">
      <div className="popup-wrapper bridge-wrapper">
        <div className="popup-box" style={{ overflow: 'hidden' }}>
          <div className="p-4 flex-end">
            <IconButton aria-label="close" onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="px-5 pb-6 bridge-body">
            {step === 0 && (
              <>
                <h4 className="mb-7-5">Send From</h4>
                <div className="flex-center">
                  <div className="flex mb-9" style={{ width: 280, padding: '0 14px' }}>
                    <img
                      src={netInfo?.[fromNetId]?.image || ''}
                      alt=""
                      className="bridge-icon mr-4"
                    />
                    <h2>{netInfo?.[fromNetId]?.label || ''}</h2>
                  </div>
                </div>

                <div className="mb-10 text-center">
                  <img src={ArrowDownIcon} alt="" style={{ height: 16 }} />
                </div>

                <h4 className="mb-7-5">Receive on</h4>
                <div className="flex-center">
                  <SelectNet fromNetId={fromNetId} handleChainNet={handleChainNet} />
                </div>
                {userAccount ? (
                  <>
                    <div className="flex-center">
                      <div
                        className="btn-normal mt-143px mb-4 text-center"
                        style={{ padding: '13px 62px', opacity: loading ? 0.5 : 1 }}
                        onClick={() => setChainNet()}
                      >
                        {approveLoading ? 'Approving...' : 'Continue'}
                      </div>
                    </div>
                    <a
                      href={`https://app.multichain.org/#/router?bridgetoken=${SWAP_CONTRACTS[fromNetId]['singleTokenAddress']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="text-center">
                        Bridge powered by Multichain{' '}
                        <span>
                          <img src={DetailIcon} alt="" style={{ width: 12 }} />
                        </span>
                      </p>
                    </a>
                  </>
                ) : (
                  <div className="flex-center">
                    <div
                      className="btn-normal mt-143px mb-4 text-center"
                      style={{ padding: '13px 62px', opacity: loading ? 0.5 : 1 }}
                      onClick={() => connectWallet()}
                    >
                      Connect Wallet
                    </div>
                  </div>
                )}
              </>
            )}
            {step === 1 && (
              <>
                <h4 className="mb-7-5">From / To</h4>
                <div className="flex-between mb-9">
                  <div className="flex">
                    <img
                      src={netInfo?.[fromNetId]?.image || ''}
                      alt=""
                      className="mr-3"
                      style={{ width: 24, height: 24 }}
                    />
                    <h3>{netInfo?.[fromNetId]?.name || ''}</h3>
                  </div>
                  <div>
                    <img src={ArrowRightIcon} alt="" style={{ width: 18 }} />
                  </div>
                  <div className="flex">
                    <img
                      src={netInfo?.[toNetId]?.image || ''}
                      alt=""
                      className="mr-3"
                      style={{ width: 24, height: 24 }}
                    />
                    <h3>{netInfo?.[toNetId]?.name || ''}</h3>
                  </div>
                </div>

                <div className="mb-3 flex-between">
                  <h4 className="">Amount</h4>
                  <h5>Balance: {balance.toFixed(2)}</h5>
                </div>
                <div className="flex-center mb-2">
                  <div className="selectmenu w-full flex">
                    <img src={LiqIcon} alt="" style={{ width: 30, height: 30 }} className="mr-3" />
                    <span className="text-18 mr-3 gray">LIQ</span>
                    <input
                      type="number"
                      min="0"
                      value={swapAmount}
                      onChange={e =>
                        setSwapAmount(e.target.value === '' ? '' : Math.abs(e.target.value))
                      }
                      placeholder="0.00"
                    />
                  </div>
                </div>
                <h6 className="mb-10 ml-4 light-gray">
                  = ${readableNumber((swapAmount || 0) * liqPrice)}
                </h6>
                <h4 className="mb-5">Receive</h4>
                <div className="flex ml-4 mb-5">
                  <img src={LiqIcon} alt="" style={{ width: 30, heigth: 30 }} className="mr-3" />
                  <span className="text-18 text-bold mr-3 gray">LIQ</span>
                  <span className="text-18 text-bold">
                    {getFee(swapAmount)?.receive?.toFixed(2)}
                  </span>
                </div>
                <h6 className="ml-4 mb-3 light-gray">
                  Fee: {getFee(swapAmount)?.fee?.toFixed(2)}
                  LIQ (${readableNumber(getFee(swapAmount)?.fee * liqPrice)})
                </h6>
                <h6 className="ml-4 mb-10 light-gray">Duration: 3 - 30 minutes</h6>
                <div className="flex-center">
                  <div
                    className="btn-normal mb-4 text-center"
                    style={{
                      padding: '13px 62px',
                      opacity: parseFloat(swapAmount) > balance || swapAmount === '' ? 0.3 : 1,
                    }}
                    onClick={() => transfer()}
                  >
                    Transfer LIQ
                  </div>
                </div>
                <p className="text-center">Confirm transaction in your wallet</p>
              </>
            )}
            {step === 2 && (
              <>
                <h4 className="mb-7-5 text-center">
                  {(status.current === 0 || status.current === 1) &&
                    `Waiting for confirmation on ${SWAP_CONTRACTS[fromNetId]['name']}...`}
                  {status.current === 2 &&
                    `Waiting for confirmation on ${SWAP_CONTRACTS[toNetId]['name']}...`}
                  {status.current === 8 && `Waiting on swapping`}
                  {(status.current === 9 || status.current === -1) &&
                    `Finalizing cross chain transfer`}
                </h4>
                {isError ? (
                  <div className="multichain">
                    <div className="flex-center mb-4">
                      <img src={WarningIcon} alt="" className="warning-icon mr-4" />
                      <h4>Action Required</h4>
                    </div>
                    <div className="text-center text-13 mb-10 text-bold">
                      It seems like the node missed this transaction. <br />
                      Don't worry, your funds are safe.
                    </div>
                    <a
                      href="https://anyswap.net/?tabparams=tools"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <h5 className="text-primary text-bold flex-center mb-10">
                        To fix it, please fill out this form
                        <span>
                          <img src={DetailIcon} alt="" className="ml-2 detail-icon" />
                        </span>
                      </h5>
                    </a>
                    <div className="text-13 mb-6 text-bold">
                      Enter the following information and send
                    </div>
                    <div className="mb-4">
                      <h6>Tx Hash: </h6>
                      <p className="txhash">
                        {txhash.current}
                        <span
                          className="ml-2"
                          onClick={() => hanldeCopyClipboard(txhash.current, 0)}
                        >
                          <img src={CopyIcon} alt="" style={{ marginBottom: -4 }} />
                        </span>
                        <span className="text-primary ml-2">{isCopied0 ? 'Copied!' : ''}</span>
                      </p>
                    </div>
                    <div className="mb-4 flex">
                      <h6>Token: </h6>
                      <p>
                        LIQ
                        <span className="ml-2" onClick={() => hanldeCopyClipboard('LIQ', 1)}>
                          <img src={CopyIcon} alt="" style={{ marginBottom: -4 }} />
                        </span>
                        <span className="text-primary ml-2">{isCopied1 ? 'Copied!' : ''}</span>
                      </p>
                    </div>
                    <div className="mb-4 flex">
                      <h6>From Chain: </h6>
                      <p>
                        {netInfo[fromNetId]['name']}
                        <span
                          className="ml-2"
                          onClick={() => hanldeCopyClipboard(netInfo[fromNetId]['name'], 2)}
                        >
                          <img src={CopyIcon} alt="" style={{ marginBottom: -4 }} />
                        </span>
                        <span className="text-primary ml-2">{isCopied2 ? 'Copied!' : ''}</span>
                      </p>
                    </div>
                    <div className="mb-4 flex">
                      <h6>To Chain: </h6>
                      <p>
                        {netInfo[toNetId]['name']}
                        <span
                          className="ml-2"
                          onClick={() => hanldeCopyClipboard(netInfo[toNetId]['name'], 3)}
                        >
                          <img src={CopyIcon} alt="" style={{ marginBottom: -4 }} />
                        </span>
                        <span className="text-primary ml-2">{isCopied3 ? 'Copied!' : ''}</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex-center">
                      <Chart percent={statusPercent} />
                    </div>
                    <div className="text-center">
                      <h4 className="pt-10" style={{ color: '#9AA6CF', fontWeight: 400 }}>
                        Estimated duration time
                      </h4>
                      <h4 className="pt-2" style={{ paddingBottom: 56 }}>
                        3 to 30 minutes
                      </h4>
                      <div className="flex-center pb-8">
                        <div className="btn-close" onClick={() => setOpenPopup(false)}>
                          Close
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {step === 3 && (
              <>
                <h4 className="mb-7-5 text-center">Transfer Successful</h4>

                <div className="flex-center checkmark-wrapper">
                  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  </svg>

                  <div className="checkmark__check">
                    <img src={CheckmarkIcon} alt="" />
                  </div>
                </div>

                <div className="text-center">
                  <div className="flex-center mb-7-5 mt-4">
                    <div className="btn-close" onClick={() => setOpenPopup(false)}>
                      Close
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 4 && (
              <>
                <h4 className="mb-7-5 text-center">Transfer Successful</h4>
                <div className="flex-center" style={{ height: 280, flexDirection: 'column' }}>
                  <img src={LiqIcon} alt="" style={{ width: 50, height: 50 }} />
                  <h1 className="result-text">
                    LIQ &nbsp;
                    <span>{getFee(swapAmount)?.receive?.toFixed(2)}</span>
                  </h1>
                  <span className="text-16 pt-2">Received</span>
                </div>
                <div className="text-center">
                  <div className="flex-center mb-7-5 mt-11">
                    <div className="btn-normal" onClick={() => changeFarmNet()}>
                      Change Farm Network
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 5 && (
              <>
                <h4 className="mb-7-5 text-center" style={{ paddingBottom: 116 }}>
                  Coming Soon
                </h4>
                <div className="text-14 text-center px-7 mb-11" style={{ fontWeight: 500 }}>
                  Use the Multichain bridge interface until it will be available directly on the
                  Liquidus farm.
                </div>
                <div className="text-center">
                  <div style={{ marginBottom: 120 }}>
                    <a
                      href="https://app.multichain.org/#/router?bridgetoken=0xABd380327Fe66724FFDa91A87c772FB8D00bE488"
                      className="btn-normal"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <span className="text-bold">Use Bridge</span>
                    </a>
                  </div>
                  <div className="flex-center pb-8">
                    <div className="btn-close" onClick={() => setOpenPopup(false)}>
                      Close
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 6 && (
              <>
                <div className="flex-center mb-4">
                  <img src={WarningIcon} alt="" className="warning-icon mr-4" />
                  <h4>Transaction Failed</h4>
                </div>
                <div className="py-40 px-7">
                  <span className="text-center text-14">
                    Cross chain bridge failed. Your tokens will be send back to your account within
                    the next minutes.
                  </span>
                </div>
                <div className="flex-center pb-8">
                  <div className="btn-close flex-center" onClick={() => setOpenPopup(false)}>
                    Close
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
