import React from 'react';
import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawerBackground from '../assets/images/Group6.png';
import Logo from '../assets/images/liquidus_logo.png';
import DropMenu from './DropMenu';

const MobileDrawer = ({ drawerOpen, setDrawerOpen, netId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    // { label: 'Features', href: 'https://liquidus.finance/#features' },
    // { label: 'Premium', href: 'https://liquidus.finance/#premium' },
    // { label: 'Roadmap', href: 'https://liquidus.finance/#stages' },
    // { label: 'Tokenomics', href: 'https://liquidus.finance/#tokenomics' },
    // { label: 'Team', href: 'https://liquidus.finance/#team' },
    // { label: 'Litepaper', href: '/#' },
    { label: 'Terms', href: 'https://docs.liquidus.finance/about-liquidus/legal' },
    { label: 'Migration', href: 'https://farm.liquidus.finance/migrate' },
  ];

  return (
    <Grid container>
     

      {/* Drawer component */}
      {isSmallScreen && (
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          {/* Header of the Drawer */}
          <Box
            sx={{
              backgroundImage: `url(${DrawerBackground})`,
              backgroundSize: 'cover',
              height: '100vh',
            }}
          >
            <Stack
              direction="row"
              sx={{ p: '10px 0px 10px 10px' }}
              alignItems="flex-start"
              spacing={2}
            >
              <Box className="pb-3">
                <a href="http://liquidus.finance">
                  <img src={Logo} alt="logo" className="logo" />
                </a>
              </Box>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            {/* List of items in the Drawer */}
            <List>
              {menuItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  component="a"
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
            <Box sx={{p: '0 10px'}}>
              <DropMenu netId={netId} />
            </Box>
          </Box>
        </Drawer>
      )}
    </Grid>
  );
};

export default MobileDrawer;
