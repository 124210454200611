import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BscIcon from '../assets/images/bitmap_logo.png';
import CronosIcon from '../assets/images/cronos-icon.svg';
import EthIcon from '../assets/images/eth-icon.svg';
import MaticIcon from '../assets/images/matic-icon.svg';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: 'rgba(17, 36, 85, 0)',
  },
}));

export default function SelectNet(props) {
  const classes = useStyles();
  const { fromNetId, handleChainNet } = props;
  const netList = [
    { name: 'BSC', label: 'Binance Smart Chain', image: BscIcon, netId: 56 },
    { name: 'CRONOS', label: 'Cronos net', image: CronosIcon, netId: 25 },
    { name: 'ETH', label: 'Ethereum net', image: EthIcon, netId: 1 },
    { name: 'MATIC', label: 'Matic net', image: MaticIcon, netId: 137 },
  ];
  const [menuId, setMenuId] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setMenuId(fromNetId === 56 ? 1 : 0);
    // setToNetId(fromNetId === 56 ? 25 : 56);
  }, [fromNetId]);

  const handleMenu = idx => {
    setMenuId(idx);
    setShowMenu(false);
    handleChainNet(fromNetId, netList[idx]['netId']);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className="selectmenu flex-between" onClick={() => setShowMenu(!showMenu)}>
          <div className="flex-center">
            <img src={netList?.[menuId]?.['image']} alt="" className="mr-3" />
            <span>{netList?.[menuId]?.['name']}</span>
          </div>
          <ChevronDownIcon style={{ fontSize: 24, color: '#112455' }} />
        </div>
        {showMenu && (
          <>
            <div className="white-droplist" style={{ width: '100%' }}>
              {netList.map((item, idx) => {
                if (item.netId === fromNetId) return null;
                return (
                  <div
                    key={idx}
                    className="white-dropitem flex-start"
                    onClick={() => handleMenu(idx)}
                  >
                    <span>
                      <img src={item.image} alt="" className="mr-3" />
                    </span>
                    <span>{item.name}</span>
                  </div>
                );
              })}
            </div>
            <div className={classes.overlay} onClick={() => setShowMenu(false)} />
          </>
        )}
      </div>
    </>
  );
}
