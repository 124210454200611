// import setAuthToken from '../utils/set-auth-token';

export const actionTypes = {
  RefreshCount: '[RefreshCount] Action',
  Web3Client: '[Web3Client] Action',
  SetNetId: '[SetNetId] Action',
};

const initialState = {
  refreshCount: 0,
  web3Client: null,
  chainNetId: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RefreshCount: {
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
      };
    }
    case actionTypes.Web3Client: {
      return {
        ...state,
        web3Client: action.payload,
      };
    }
    case actionTypes.SetNetId: {
      return {
        ...state,
        chainNetId: action.payload,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  changeRefresh: () => ({
    type: actionTypes.RefreshCount,
  }),
  setWeb3: data => ({
    type: actionTypes.Web3Client,
    payload: data,
  }),
  setChainNetId: data => ({
    type: actionTypes.SetNetId,
    payload: data,
  }),
};
