import { getDefaultWallet } from '../helper/connect-wallet';

export const actionTypes = {
  UpdateWallet: '[UpdateWallet] Action',
};

const initialState = {
  wallet: getDefaultWallet(),
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateWallet: {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  updateWalletAction: data => ({
    type: actionTypes.UpdateWallet,
    payload: data,
  }),
};
