import React, { useState, useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LiquidusLogo from '../assets/images/liquidus_logo_round.png';
import BnbLogo from '../assets/images/bitmap_logo.png';
import BusdLogo from '../assets/images/busd.svg';
import UsdcLogo from '../assets/images/usdc.svg';
import { getMintTime, parseBNumber, readableNumber } from '../services/utils';
// import { parseBNumber, readableNumber } from '../services/utils';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/common.redux';
import { useCallback } from 'react';
import Spinner from './Spinner';
import MailIcon from '../assets/images/mail-dark.png';
import { useRef } from 'react';

export default function MintNFTPopup(props) {
  const {
    setOpenPopup,
    setOpenBuyPopup,
    setFarmPopup,
    setOpenWalletPopup,
    web3,
    userAccount,
    nft,
  } = props;
  const dispatch = useDispatch();
  const [approvedLiq, setApprovedLiq] = useState(false);
  const [approvedBusd, setApprovedBusd] = useState(false);
  const [liqTokenContract, setLiqTokenContract] = useState(null);
  const [busdTokenContract, setBusdTokenContract] = useState(null);
  const [nftContract, setNftContract] = useState(null);
  const [mintState, setMintState] = useState(false);
  const [mintable, setMintable] = useState(true);
  const [liqAmount, setLiqAmount] = useState(0);
  const [liqBalance, setLiqBalance] = useState(0);
  const [token2ndAmount, setToken2ndAmount] = useState(0);
  const [token2ndBalance, setToken2ndBalance] = useState(0);
  const [loadingMint, setLoadingMint] = useState(false);
  const [loadingApproveLiq, setLoadingApproveLiq] = useState(false);
  const [loadingApproveToken2nd, setLoadingApproveToken2nd] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [soldout, setSoldout] = useState(false);
  const [email, setEmail] = useState('');

  const decimals = 18;
  const mintTime = getMintTime(nft?.endDate);
  const mailPostURL =
    'https://finance.us20.list-manage.com/subscribe/post?u=b1815d06066e8eec2e2b69b80&id=b303e35632';

  // Timer
  let tmr = useRef();
  let duration = useRef(-1000);
  const [remainTime, setRemainTime] = useState('00 : 00 : 00');

  useEffect(() => {
    // Time downCounter
    if (!tmr.current) {
      tmr.current = setInterval(async () => {
        if (duration.current <= -1000) {
          duration.current = -1000;
          setRemainTime('00 : 00 : 00');
        } else {
          const duration_in_sec = Math.round(duration.current / 1000);
          const hor = Math.floor(duration_in_sec / 3600);
          const min = Math.floor((duration_in_sec % 3600) / 60);
          const sec = duration_in_sec % 60;
          setRemainTime(
            `${hor < 10 ? 0 : ''}${hor} : ${min < 10 ? 0 : ''}${min} : ${sec < 10 ? 0 : ''}${sec}`,
          );
          duration.current -= 1000;
        }
      }, 1000);
    }

    return () => {
      clearInterval(tmr.current);
    };
  }, []);

  const initProcess = useCallback(async () => {
    if (!web3 || !nft || !userAccount) return;
    /* Check if Liq and Busd are approved */
    setInitLoading(true);
    try {
      const liq_token_contract = new web3.eth.Contract(nft.liqTokenAbi, nft.liqContract);
      setLiqTokenContract(liq_token_contract);
      const allowance_liq = await liq_token_contract.methods
        .allowance(userAccount, nft.nftContract)
        .call();
      setApprovedLiq(allowance_liq > 0);

      const token2nd_contract = new web3.eth.Contract(nft.token2ndAbi, nft.token2ndContract);
      setBusdTokenContract(token2nd_contract);
      const allowance_token2nd = await token2nd_contract.methods
        .allowance(userAccount, nft.nftContract)
        .call();
      setApprovedBusd(allowance_token2nd > 0);

      const decimal_token2nd = await token2nd_contract.methods.decimals().call();

      const nft_contract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
      setNftContract(nft_contract);
      const nftInfo = await nft_contract.methods.NftInfo(nft.nftID).call();
      const liq = parseBNumber(nftInfo.priceInLIQ, decimals);
      setLiqAmount(liq);
      const token2nd =
        nft.token2nd === 'BNB'
          ? parseBNumber(nftInfo.priceInBNB, decimal_token2nd)
          : parseBNumber(nftInfo.priceIn2ndToken, decimal_token2nd);
      setToken2ndAmount(token2nd);

      const balanceOfLiq = await liq_token_contract.methods.balanceOf(userAccount).call();
      setLiqBalance(parseBNumber(balanceOfLiq, decimals));

      const balanceOfToken2nd = await token2nd_contract.methods.balanceOf(userAccount).call();
      setToken2ndBalance(parseBNumber(balanceOfToken2nd, decimal_token2nd));

      const diff = mintTime - new Date().getTime();
      console.log('diff', diff);
      if (diff > 0) {
        duration.current = diff;
      }

      setInitLoading(false);
    } catch (err) {
      console.log(err);
      setInitLoading(false);
    }
  }, [web3, userAccount, nft, mintTime]);

  useEffect(() => {
    initProcess();
  }, [initProcess]);

  const approveLIQ = async () => {
    if (!userAccount) return;
    if (initLoading) return;

    setLoadingApproveLiq(true);
    try {
      await liqTokenContract?.methods
        ?.approve(
          nft.nftContract,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        )
        .send({ 
          from: userAccount,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null, 
        });

      setLoadingApproveLiq(false);
      setApprovedLiq(true);
    } catch (err) {
      setLoadingApproveLiq(false);
      console.log(err);
    }
  };

  const approveBUSD = async () => {
    if (!userAccount) return;
    if (initLoading) return;

    setLoadingApproveToken2nd(true);
    try {
      await busdTokenContract?.methods
        ?.approve(
          nft.nftContract,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        )
        .send({ 
          from: userAccount,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null, 
        });

      setLoadingApproveToken2nd(false);
      setApprovedBusd(true);
    } catch (err) {
      setLoadingApproveToken2nd(false);
      console.log(err);
    }
  };

  const mintNFT = async () => {
    if (duration.current > 0) return;
    if (initLoading) return;

    setLoadingMint(true);
    const nftInfo = await nftContract.methods.NftInfo(nft.nftID).call();
    setLoadingMint(false);
    if (nftInfo.maximumAmount === nftInfo.mintedAmount) {
      setSoldout(true);
    } else {
      if (liqBalance < liqAmount || token2ndBalance < token2ndAmount) {
        setMintable(false);
      } else {
        setMintable(true);
        setMintState(false);
        setLoadingMint(true);
        try {
          await nftContract?.methods?.mint(1, nft.nftID).send({ 
            from: userAccount,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null, 
          });
          dispatch(actions.changeRefresh());
          setMintState(true);
          setLoadingMint(false);
        } catch (err) {
          console.log(err);
          setLoadingMint(false);
        }
      }
    }
  };

  const handleSubmit = (e, subscribe) => {
    e.preventDefault();
    email && email.indexOf('@') > -1 && subscribe({ EMAIL: email });
  };

  const isApproved = () => {
    if ((liqAmount > 0 && !approvedLiq) || (token2ndAmount > 0 && !approvedBusd)) return false;
    return true;
  };

  return (
    <div className="bg-overlay flex-center">
      <div className="popup-wrapper">
        {soldout ? (
          // Soldout popup
          <div className="popup-box">
            <div className="card-header flex-between">
              <h4 style={{ fontWeight: 500 }}>Sold Out</h4>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenPopup(false);
                  clearInterval(tmr);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body soldout">
              <div className="flex-center">
                <div className="nft-icon flex-center">
                  <img src={nft.smallImage} alt="" />
                </div>
                <div style={{ marginLeft: 25, flex: 1 }}>
                  <h4 className="mb-2">{nft.name} NFT is sold out.</h4>
                  <p>
                    Sign up with email and be the first to get notified when the next NFT drop
                    releases.
                  </p>
                </div>
              </div>
              <div className="soldout-desc flex-center">
                <img src={MailIcon} alt="" />
                <p>
                  Everyone (really) loves this one. We're sold out for now - but sign up to receive
                  an email alert for when it's back in stock.
                </p>
              </div>
              <MailchimpSubscribe
                url={mailPostURL}
                render={({ subscribe, status, message }) => (
                  <>
                    <form onSubmit={e => handleSubmit(e, subscribe)}>
                      <div className="email-input flex-center">
                        <input
                          type="text"
                          placeholder="Email address"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                        <button type="submit" className="flex-center">
                          {status === 'sending' ? 'Sending...' : 'Notify me'}
                        </button>
                      </div>
                      {status === 'error' && (
                        <div
                          dangerouslySetInnerHTML={{ __html: message }}
                          className="notif-box error"
                        />
                      )}
                      {status === 'success' && (
                        <div
                          dangerouslySetInnerHTML={{ __html: message }}
                          className="notif-box success"
                        />
                      )}
                    </form>
                  </>
                )}
              />
              <div className="flex-center" style={{ paddingTop: 86 }}>
                <p style={{ fontSize: 14 }}>
                  or join Liquidus Announcements on{' '}
                  <span>
                    <a href="/" style={{ fontWeight: 600 }}>
                      Telegram
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="popup-box">
            <div className="card-header flex-between">
              <h4 style={{ fontWeight: 500 }}>Purchase Liquidus NFT</h4>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenPopup(false);
                  clearInterval(tmr);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="card-body">
              <div className="process-bar">
                <span className="circle-badge active-badge" style={{ marginLeft: 48 }}>
                  1
                </span>
                <hr />
                <span
                  className={`circle-badge ${
                    approvedLiq && approvedBusd ? 'active-badge' : 'inactive-badge'
                  }`}
                  style={{ marginRight: 22 }}
                >
                  2
                </span>
              </div>
              <div className="flex-between">
                <span className="active-label">Approve Tokens</span>
                <span
                  className={`${approvedLiq && approvedBusd ? 'active-label' : 'inactive-label'}`}
                >
                  Mint NFT
                </span>
              </div>
              {mintState ? (
                <div style={{ paddingTop: 58 }}>
                  <div>
                    <h4 className="mint-logo-label text-primary text-center">Successful</h4>
                    <h5
                      style={{
                        fontSize: 20,
                        color: '#9AA6CF',
                        textAlign: 'center',
                        paddingTop: 30,
                      }}
                    >
                      Do you want to farm the NFT?
                    </h5>
                  </div>
                  <div className="button-group flex-center">
                    <button className="btn-stake btn-cancel" onClick={() => setOpenPopup(false)}>
                      Close
                    </button>
                    <button
                      className="btn-stake btn-confirm"
                      onClick={() => {
                        setOpenPopup(false);
                        setFarmPopup(true);
                        clearInterval(tmr);
                      }}
                    >
                      Farm NFT
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {userAccount ? (
                    <>
                      {isApproved() ? (
                        <div className="pt-10">
                          <div className="flex-around">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div className="nft-icon flex-center mb-2">
                                <img src={nft.smallImage} alt="" />
                              </div>
                              <p style={{ textAlign: 'center', fontWeight: 600 }}>{nft.name}</p>
                            </div>
                            <div>
                              {liqAmount > 0 && (
                                <div className="flex-start pb-3">
                                  <img src={LiquidusLogo} alt="" className="mint-logo-icon" />
                                  <div className="pl-3 pt-4">
                                    <div>
                                      <span className="mint-logo-label text-primary text-bold">
                                        {liqAmount} LIQ
                                      </span>
                                    </div>
                                    <div>
                                      <span className="balance-price">
                                        Balance: {readableNumber(liqBalance)} LIQ
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {token2ndAmount > 0 && (
                                <div className="flex-start">
                                  <img
                                    src={
                                      nft.token2nd === 'BNB'
                                        ? BnbLogo
                                        : nft.token2nd === 'BUSD'
                                        ? BusdLogo
                                        : nft.token2nd === 'USDC'
                                        ? UsdcLogo
                                        : null
                                    }
                                    alt=""
                                    className="mint-logo-icon"
                                  />
                                  <div className="pl-3 pt-4">
                                    <div>
                                      <span
                                        className={`mint-logo-label text-bold ${
                                          nft.token2nd === 'BUSD'
                                            ? 'busd-color'
                                            : nft.token2nd === 'USDC'
                                            ? 'usdc-color'
                                            : ''
                                        }`}
                                      >
                                        {`${token2ndAmount} ${nft?.token2nd}`}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="balance-price">
                                        {`Balance: ${readableNumber(token2ndBalance)} ${
                                          nft?.token2nd
                                        }`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {!mintable && (
                            <div className="flex-center mt-4">
                              <h5 style={{ fontSize: '18px', color: 'red', fontWeight: 500 }}>
                                Insufficient token balance.
                              </h5>
                              {/* <a
                                                        href="https://exchange.biswap.org/#/swap?outputCurrency=0xc7981767f644C7F8e483DAbDc413e8a371b83079"
                                                        target='_blank' rel="noopener noreferrer"
                                                        style={{fontSize: '18px', fontWeight: 500}}
                                                        className='ml-2 text-primary'
                                                    >
                                                        Get LIQ here
                                                    </a> */}
                              <span
                                style={{ fontSize: '18px', fontWeight: 500 }}
                                className="ml-2 text-primary cursor-pointer"
                                onClick={() => {
                                  setOpenBuyPopup(true);
                                  setOpenPopup(false);
                                }}
                              >
                                Get LIQ here
                              </span>
                            </div>
                          )}
                          {duration.current >= 0 && (
                            <div className="flex-center mt-4">
                              <h5 style={{ fontSize: '18px', color: 'red', fontWeight: 500 }}>
                                Left Time: {remainTime}
                              </h5>
                            </div>
                          )}
                          <div className="button-group flex-center">
                            <div
                              className="btn-stake btn-cancel flex-center"
                              onClick={() => {
                                setOpenPopup(false);
                                clearInterval(tmr);
                              }}
                            >
                              Cancel
                            </div>
                            <div
                              className="btn-stake btn-confirm flex-center"
                              onClick={() => mintNFT()}
                            >
                              {loadingMint ? <Spinner /> : <span>Mint NFT</span>}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pt-21">
                          <div className="flex-around">
                            {!approvedLiq && liqAmount > 0 && (
                              <div className="flex-start">
                                <img src={LiquidusLogo} alt="" className="mint-logo-icon" />
                                <span className="mint-logo-label text-primary pl-3">LIQ</span>
                              </div>
                            )}
                            {!approvedBusd && token2ndAmount > 0 && (
                              <div className="flex-start">
                                <img
                                  src={
                                    nft.token2nd === 'BNB'
                                      ? BnbLogo
                                      : nft.token2nd === 'BUSD'
                                      ? BusdLogo
                                      : nft.token2nd === 'USDC'
                                      ? UsdcLogo
                                      : null
                                  }
                                  alt=""
                                  className="mint-logo-icon"
                                />
                                <span
                                  className={`mint-logo-label pl-3 ${
                                    nft.token2nd === 'BUSD'
                                      ? 'busd-color'
                                      : nft.token2nd === 'USDC'
                                      ? 'usdc-color'
                                      : ''
                                  }`}
                                >
                                  {nft?.token2nd}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex-center" style={{ paddingTop: '73px' }}>
                            {!approvedLiq && liqAmount > 0 && (
                              <div
                                className="btn-stake btn-confirm flex-center"
                                style={{ width: '50%' }}
                                onClick={() => approveLIQ()}
                              >
                                {loadingApproveLiq ? <Spinner /> : <span>Approve LIQ</span>}
                              </div>
                            )}
                            {!approvedBusd && token2ndAmount > 0 && (
                              <div
                                className="btn-stake btn-confirm flex-center"
                                style={{ width: '50%' }}
                                onClick={() => approveBUSD()}
                              >
                                {loadingApproveToken2nd ? (
                                  <Spinner />
                                ) : (
                                  <span>Approve {nft?.token2nd}</span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex-center pt-10">
                      <button
                        className="btn-round text-btn"
                        onClick={() => {
                          setOpenPopup(false);
                          setOpenWalletPopup(true);
                        }}
                      >
                        Connect Wallet
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="card-footer flex-center text-semibold">
              {approvedLiq && approvedBusd ? (
                <p style={{ fontSize: 14, opacity: 0.8, textAlign: 'center', padding: '0 16px' }}>
                  The token amounts shown will be deducted from your wallet.
                </p>
              ) : (
                <p style={{ fontSize: 14, opacity: 0.8, textAlign: 'center', padding: '0 16px' }}>
                  Please confirm the transaction in your wallet.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
