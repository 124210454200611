export const BSCSCAN_API_URL = 'https://api.bscscan.com/api';
export const TESTNET_API_URL = 'https://api-testnet.bscscan.com/api';
export const API_KEY = 'X8IAD5N2GYDFSHNZX69VWE9JMRW4SI5QRJ';
export const COMPOUNDING_PERIOD = 365;
// export const BACKEND_URL = 'http://localhost:5000';
export const BACKEND_URL = 'https://farmdatabase.herokuapp.com';
export const TRACKING_ID = 'UA-226084257-1';
export const GA_MEASUREMENT_ID = 'G-2QQ85Z4FHE';
export const LIQ_POT = 'files/LIQ_POT.csv';
export const POT_ADDRESSES = 'files/POT_ADDRESSES.csv';
export const POT_MULTIPLIER15X = 'files/POT_Multiplier1.5X.csv';
export const POT_MULTIPLIER2X = 'files/POT_Multiplier2X.csv';
