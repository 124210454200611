import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EthIcon from '../assets/images/eth-icon.svg';
import CronosIconOne from '../assets/images/cronos.svg';
import BinanceIcon from '../assets/images/bitmap_logo.png';
import MaticIcon from '../assets/images/matic-icon.svg';
import { actions } from '../redux/common.redux';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: 'rgba(17, 36, 85, 0)',
  },
}));

export default function DropMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { netId, isBlack } = props;
  const [menuId, setMenuId] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const menuList = [
    { name: 'BSC', image: BinanceIcon, netId: 56 },
    { name: 'ETH', image: EthIcon, netId: 1 },
    { name: 'CRONOS', image: CronosIconOne, netId: 25 },
    { name: 'POLYGON', image: MaticIcon, netId: 137 },
  ];

  useEffect(() => {
    let initMenuId = 0;
    switch (netId) {
      case 56:
        initMenuId = 0;
        break;
      case 1:
        initMenuId = 1;
        break;
      case 25:
        initMenuId = 2;
        break;
      case 137:
        initMenuId = 3;
        break;
      default:
        initMenuId = 0;
        break;
    }
    setMenuId(initMenuId);
  }, [netId]);

  const handleMenu = id => {
    setMenuId(id);
    const net_id = menuList[id]['netId'];
    localStorage.setItem('netId', net_id);
    setShowMenu(false);
    // window.location.reload();
    dispatch(actions.changeRefresh());
  };

  return (
    <>
      <Box style={{ position: 'relative' }}>
        <div
          className="dropmenu flex-between"
          style={{ borderRadius: 10, borderColor: 'rgba(26, 53, 86, 0.15)' }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="flex-center">
            <img
              src={menuList?.[menuId]?.['image']}
              alt=""
              className={`mr-3 ${menuId === 2 ? 'drop_cronos' : ''} `}
            />
            <span
              style={{
                color: isBlack ? 'black' : '#112455',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: '500',
              }}
            >
              {menuList?.[menuId]?.['name']}
            </span>
          </div>
          <ChevronDownIcon style={{ fontSize: 24, color: !isBlack ? '#112455' : '#000000' }} />
        </div>
        {showMenu && (
          <>
            <div className="droplist">
              {menuList.map((item, idx) => (
                <div key={idx} className="dropitem flex-start" onClick={() => handleMenu(idx)}>
                  <span>
                    <img src={item.image} alt="" className="mr-3" />
                  </span>
                  <span
                    style={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '500',
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
            <Box className={classes.overlay} onClick={() => setShowMenu(false)} />
          </>
        )}
      </Box>
    </>
  );
}
