// import FarmContract from './FarmContract.json';
//import FarmContractVesting from './FarmContractVesting.json';
import FarmContractForSingleToken from './FarmContractForSingleToken.json';
import LIQSingleToken from './LIQSingleToken.json';
import Classic_LIQ_ABI from './Classic_LIQ_ABI.json';
import LIQ_Migration_ABI from './LIQ_Migration_ABI.json';
import V2_LIQ_Migration_ABI from "./V2_LIQ_Migration_ABI.json";
import LPToken from './LPToken.json';
import NFT_HelmetContract from './NFT_HelmetContract.json';
import NFT_HelmetStakingContract from './NFT_HelmetStakingContract.json';
import BNB_ABI from './BNB_ABI.json';
import LIQ_ABI from './LIQ_ABI.json';
import LIQ_HOLDING_ABI from './LIQHolding.json';
import Crona_LP_Token from './Crona_LP_Token.json';
import SwapBscABI from './SwapBSC_ABI.json';
import SwapCronosABI from './SwapCRONOS_ABI.json';
import SwapMaticABI from './SwapMATIC_ABI.json';
import SwapEthABI from './SwapETH_ABI.json';
import BuyLiqABI from './BUY_LIQ_ABI.json';
import BuyLiqCronosABI from './BUY_LIQ_CRONOS_ABI.json';
import CoinmarketABI from './CM_ABI.json';
import CLASSIC_LIQ_ETH_ABI from './CLASSIC_LIQ_ETH_ABI.json';
import CLASSIC_LIQ_CRONOS_ABI from './CLASSIC_LIQ_CRONOS_ABI.json';
import CLASSIC_LIQ_POLYGON_ABI from './CLASSIC_LIQ_POLYGON_ABI.json';

export const COINMARKET_CONTRACT = {
  address: '0x1730C92D7fc02fccB178AD183F9d91E367642E28',
  abi: CoinmarketABI,
};

export const CONTRACT_FOR_BNB = {
  address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
  abi: BNB_ABI,
};

export const LIQ_ETH_CONTRACT = {
  address: '0x13c869bb9519353643269846ad6f96F59c1F4F3e',
  abi: Crona_LP_Token,
};

export const LIQ_BNB_CONTRACT = {
  address: '0x23C75a239fEE6C1cff65f3191C7ec57acCDc4513', //lpSwapToken - PancakeSwap
  abi: LIQ_ABI,
};

export const LIQ_CRO_CONTRACT = {
  address: '0x3295007761C290741B6b363b86dF9ba3467F0754',
  abi: Crona_LP_Token,
};

export const LIQ_MATIC_CONTRACT = {
  address: '0x2F5a5E6FF15B9B342e6292e2F179e7199f88922F',
  abi: Crona_LP_Token,
};

export const LIQ_HOLDING_CONTRACTS = [
  {
    netId: 56,
    address: '0x56477d0d4718eB5eEFBFfc083acde179146c68F3',
    abi: LIQ_HOLDING_ABI,
  },
  {
    netId: 25,
    address: '0x62A49fd455b71BFFF8801568D6D127a11BEc352e',
    abi: LIQ_HOLDING_ABI,
  },
];

export const SUPPORTED_NET = [1, 56, 97, 25, 137];

export const RPC = {
  1: {
    url: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    name: 'ethereum mainnet',
    explorerUrl: 'https://etherscan.io/',
  },
  56: {
    url: 'https://bsc-dataseed1.binance.org',
    name: 'bscscan',
    explorerUrl: 'https://bscscan.com/address/',
  },
  97: {
    url: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    name: 'test',
    explorerUrl: 'https://testnet.bscscan.com/address/',
  },
  25: {
    url: 'https://evm-cronos.crypto.org',
    name: 'cronos',
    explorerUrl: 'https://cronos.crypto.org/explorer/address/',
  },
  137: {
    url: 'https://polygon-rpc.com',
    name: 'polygon',
    explorerUrl: 'https://polygonscan.com/address/',
  },
};

const ethNet = [
  {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
];
const bscNet = [
  {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
];
const cronosNet = [
  {
    chainId: '0x19',
    chainName: 'Cronos',
    nativeCurrency: {
      name: 'CRO',
      symbol: 'CRO',
      // decimals: 18
    },
    rpcUrls: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer/'],
  },
];
const polygonNet = [
  {
    chainId: '137',
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/address/'],
  },
];
export const NETWORK_INFO = {
  1: ethNet,
  56: bscNet,
  25: cronosNet,
  137: polygonNet,
};

export const ETH_CONTRACT_LIST = [
  
];

export const BSC_CONTRACT_LIST = [
  // {
  //   address: '0xd23f676B8a0072F74eF1F0D95E5ef72019ba2777',
  //   lpSwapToken: '0x23C75a239fEE6C1cff65f3191C7ec57acCDc4513',
  //   rewardToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
  //   abi: FarmContractVesting,
  //   tokenAbi: LPToken,
  //   vesting: 1, // month
  //   type: 'LP',
  //   name: 'LIQ - BNB',
  //   title: 'PancakeSwap',
  //   contractLink: 'https://bscscan.com/address/0xd23f676B8a0072F74eF1F0D95E5ef72019ba2777',
  //   link: 'https://pancakeswap.finance/add/BNB/',
  //   linkName: 'LIQ-BNB LP',
  // },
  // {
  //   address: '0xa44504B0Dd282bC3189dED0a55459e0BC7E27181',
  //   lpSwapToken: '0xe9B66254f9fD300e71b35ff081a678F6BD64e665',
  //   rewardToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
  //   abi: FarmContractVesting,
  //   tokenAbi: LPToken,
  //   vesting: 1, // month
  //   type: 'LP',
  //   name: 'LIQ - ETH',
  //   title: 'PancakeSwap',
  //   contractLink: 'https://bscscan.com/address/0xa44504B0Dd282bC3189dED0a55459e0BC7E27181',
  //   link: 'https://pancakeswap.finance/add/BNB/',
  //   linkName: 'LIQ-BNB LP',
  // },  
  {
    address: '0xc864D1eCF4dD39e9A88066bd0EE0ee53E8c31cE2',
    stakedToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    rewardToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    abi: FarmContractForSingleToken,
    tokenAbi: LIQSingleToken,
    vesting: 3, // month
    type: 'LIQ',
    name: 'LIQ - Single Token',
    multiplier: 15,
    contractLink: 'https://bscscan.com/address/0xc864D1eCF4dD39e9A88066bd0EE0ee53E8c31cE2',
    link: 'https://farm.liquidus.finance/buyliq',
    linkName: 'LIQ',
  },  
  {
    address: '0xD7ee74B5A37C2182eA7870612b9A43fA3702534e',
    stakedToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    rewardToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    abi: FarmContractForSingleToken,
    tokenAbi: LIQSingleToken,
    vesting: 6, // month
    type: 'LIQ',
    name: 'LIQ - Single Token',
    multiplier: 15,
    contractLink: 'https://bscscan.com/address/0xD7ee74B5A37C2182eA7870612b9A43fA3702534e',
    link: 'https://farm.liquidus.finance/buyliq',
    linkName: 'LIQ',
  },
  {
    address: '0xDA3725d42913b8EB737c5C789C9a76bE50C52E71',
    stakedToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    rewardToken: '0x2749c9f2f8568d389bbf61ed77784a43c3cd3e19',
    abi: FarmContractForSingleToken,
    tokenAbi: LIQSingleToken,
    vesting: 12, // month
    type: 'LIQ',
    name: 'LIQ - Single Token',
    multiplier: 25,
    contractLink: 'https://bscscan.com/address/0xDA3725d42913b8EB737c5C789C9a76bE50C52E71',
    link: 'https://farm.liquidus.finance/buyliq',
    linkName: 'LIQ',
  },
];

export const CRONOS_CONTRACT_LIST = [
  
];

export const MATIC_CONTRACT_LIST = [
  
];

export const TOKEN_ABI = {
  LP: LPToken,
  LIQ: LIQSingleToken,
};

// Test net
// export const NFT_LIST = [
//     {
//         nftID: 1,
//         type: 'Helmet',
//         name: 'LIQ Helmet',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0x6753a23402e44EF7518812e731B9B2Ac36DAEbd2',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
//     {
//         nftID: 2,
//         type: 'Gate',
//         name: 'Gate To Liquidus',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0xcDeEeA9d3120eCBb9aFbD0Faff4E9D6bc3313a70',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
//     {
//         nftID: 3,
//         type: 'Temple',
//         name: 'Liquidus Temple',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0xE81e8bab5E4362d7597A6780B0AA7B6f10ed41Cb',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
// ]

export const NFT_LIST = [
  {
    nftID: 12,
    netId: 56,
    type: 'Helmet',
    name: 'New LIQ NFT',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT12_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT12_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0xFfD36fdCCCA6e3dd72Cb99DdfB2AD6AF54D56c05',
    nftStaking: '0xd6499f34b7657f88B95c992D1C2Afc41fc0aAca6',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2024-01-12',
    netUrl: 'https://bscscan.com',
  },
  {
    nftID: 13,
    netId: 56,
    type: 'Gate',
    name: 'New LIQ NFT',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT13_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT13_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0xFfD36fdCCCA6e3dd72Cb99DdfB2AD6AF54D56c05',
    nftStaking: '0x2a09470aF47becCfD97885835C8dC421550ea8A4',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2024-01-12',
    netUrl: 'https://bscscan.com',
  },
  {
    nftID: 14,
    netId: 56,
    type: 'Temple',
    name: 'New LIQ NFT',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT14_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT14_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0xFfD36fdCCCA6e3dd72Cb99DdfB2AD6AF54D56c05',
    nftStaking: '0x31771217B3183BE23d4ec89A930A8F844614E054',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2024-01-12',
    netUrl: 'https://bscscan.com',
  },
];

export const CRONOS_NFT_LIST = [
  // {
  //   nftID: 8,
  //   netId: 25,
  //   type: 'absolute',
  //   name: 'LIQ “Meta” Helmet (absolute)',
  //   smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT8_1200.png',
  //   fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT8_full.png',
  //   token2nd: 'USDC',
  //   liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
  //   token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
  //   nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
  //   nftStaking: '0xAc66d660294A3a15336B5C4Dde77523dDF66c545',
  //   nftAbi: NFT_HelmetContract,
  //   nftStakingAbi: NFT_HelmetStakingContract,
  //   liqTokenAbi: LIQSingleToken,
  //   token2ndAbi: LIQSingleToken,
  //   endDate: '2023-02-07',
  //   netUrl: 'https://cronoscan.com',
  //   liqRewards: 1000,
  // },
  // {
  //   nftID: 9,
  //   netId: 25,
  //   type: 'extension',
  //   name: 'LIQ “Meta” Helmet (extension)',
  //   smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT9_1200.png',
  //   fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT9_full.png',
  //   token2nd: 'USDC',
  //   liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
  //   token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
  //   nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
  //   nftStaking: '0x36D208a540E32FA77BF2592c3d06Ae30e6A63295',
  //   nftAbi: NFT_HelmetContract,
  //   nftStakingAbi: NFT_HelmetStakingContract,
  //   liqTokenAbi: LIQSingleToken,
  //   token2ndAbi: LIQSingleToken,
  //   endDate: '2023-02-07',
  //   netUrl: 'https://cronoscan.com',
  //   liqRewards: 500,
  // },
  // {
  //   nftID: 10,
  //   netId: 25,
  //   type: 'origins',
  //   name: 'LIQ “Meta” Helmet (origins)',
  //   smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT10_1200.png',
  //   fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT10_full.png',
  //   token2nd: 'USDC',
  //   liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
  //   token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
  //   nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
  //   nftStaking: '0xFB3de800424e365F45666d30823A945BE2a6c4eD',
  //   nftAbi: NFT_HelmetContract,
  //   nftStakingAbi: NFT_HelmetStakingContract,
  //   liqTokenAbi: LIQSingleToken,
  //   token2ndAbi: LIQSingleToken,
  //   endDate: '2023-02-07',
  //   netUrl: 'https://cronoscan.com',
  //   liqRewards: 200,
  // },
];

export const POSEIDON_NFT_LIST = [
  {
    nftID: 4,
    netId: 56,
    type: 'poseidon-1',
    name: 'Poseidon I',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT4_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT4_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
    nftStaking: '0xB5c2058FD2075F4Ce8406B2AF51d2a08c4A02f2D',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2022-12-15',
    netUrl: 'https://bscscan.com',
  },
  {
    nftID: 5,
    netId: 56,
    type: 'poseidon-2',
    name: 'Poseidon II',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT5_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT5_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
    nftStaking: '0x9992D7d7523214bcFCCe0e15E9365Db5De567D6B',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2022-12-15',
    netUrl: 'https://bscscan.com',
  },
  {
    nftID: 6,
    netId: 56,
    type: 'poseidon-3',
    name: 'Poseidon III',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT6_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT6_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
    nftStaking: '0x1512A82d2ADDceff313C2BF326c84D63caa752E2',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2022-12-15',
    netUrl: 'https://bscscan.com',
  },
];

export const EARLY_ADOPTER_NFT_LIST = [
  {
    nftID: 7,
    netId: 56,
    type: 'early-adopter',
    name: 'LIQ Early Adopter',
    smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT7_1200.png',
    fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT7_full.png',
    token2nd: 'BUSD',
    liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
    nftStaking: '0x22015f8F09aa3c04681c748ab69effE72E3f0013',
    nftAbi: NFT_HelmetContract,
    nftStakingAbi: NFT_HelmetStakingContract,
    liqTokenAbi: LIQSingleToken,
    token2ndAbi: LIQSingleToken,
    endDate: '2022-12-24',
    netUrl: 'https://bscscan.com',
  },
];

export const SWAP_CONTRACTS = {
  56: {
    name: 'BNB',
    abi: SwapBscABI,
    address: '0xf9736ec3926703e85C843FC972BD89A7f8E827C0',
    tokenAddress: '0x4b106b91c5fe4ae933cda197b9caf91a07bdae15',
    singleTokenAddress: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    tokenAbi: LIQSingleToken,
  },
  25: {
    name: 'CRONOS',
    abi: SwapCronosABI,
    address: '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    tokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
    singleTokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
    tokenAbi: Crona_LP_Token,
  },
  1: {
    name: 'ETH',
    abi: SwapEthABI,
    address: '0xe95fd76cf16008c12ff3b3a937cb16cd9cc20284',
    tokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
    singleTokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
    tokenAbi: LIQSingleToken,
  },
  137: {
    name: 'MATIC',
    abi: SwapMaticABI,
    address: '0xafaace7138ab3c2bcb2db4264f8312e1bbb80653',
    tokenAddress: '0x4036f3d9c45a20f44f0b8b85dd6ca33005ff9654',
    singleTokenAddress: '0x4036f3d9c45a20f44f0b8b85dd6ca33005ff9654',
    tokenAbi: LIQSingleToken,
  },
};

export const BRIDGE_API = 'https://bridgeapi.anyswap.exchange/v3/serverinfoV3';
export const HISTORY_API = 'https://bridgeapi.anyswap.exchange/v2/history/details';

export const BUY_LIQ_CONTRACTS = {
  1: {
    address1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    address2: '',
    abi: BuyLiqABI,
    tokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
    tokenAbi: LIQSingleToken,
    pathArray: [
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
    ],
  },
  56: {
    address1: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
    address2: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    abi: BuyLiqABI,
    tokenAddress: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    tokenAbi: LIQSingleToken,
    pathArray: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xc7981767f644c7f8e483dabdc413e8a371b83079',
    ],
  },
  25: {
    address1: '0xcd7d16fb918511bf7269ec4f48d61d79fb26f918',
    address2: '0x145677FC4d9b8F19B5D56d1820c48e0443049a30',
    abi: BuyLiqCronosABI,
    tokenAddress: '0xabd380327fe66724ffda91a87c772fb8d00be488',
    tokenAbi: Crona_LP_Token,
    pathArray: [
      '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23',
      '0xabd380327fe66724ffda91a87c772fb8d00be488',
    ],
  },
  137: {
    address1: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
    address2: '',
    abi: BuyLiqABI,
    tokenAddress: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
    tokenAbi: LIQSingleToken,
    pathArray: [
      '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
    ],
  },
};

export const CLASSIC_LIQ_CONTRACT = {
  abi: Classic_LIQ_ABI,
  tokenAbi: Classic_LIQ_ABI,
  singleTokenAddress: '0xc7981767f644C7F8e483DAbDc413e8a371b83079', //LIQ Token
};

export const MIGRATE_LIQ_CONTRACT = {
  abi: LIQ_Migration_ABI,
  tokenAbi: LIQ_Migration_ABI,
  singleTokenAddress: '0xF04E01971B13cF0E920E16c7F9C4270c378A1043',
};

export const V2_MIGRATE_LIQ_CONTRACT = {
  abi: V2_LIQ_Migration_ABI,
  tokenAbi: V2_LIQ_Migration_ABI,
  singleTokenAddress: "0x77cdA2E252D4B9a2adBBf20132e7D8671207B3Ed",
};

export const CLASSIC_LIQ_ETH_CONTRACT = {
  abi: CLASSIC_LIQ_ETH_ABI,
  tokenAbi: CLASSIC_LIQ_ETH_ABI,
  singleTokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
};

export const CLASSIC_LIQ_POLYGON_CONTRACT = {
  abi: CLASSIC_LIQ_POLYGON_ABI,
  tokenAbi: CLASSIC_LIQ_POLYGON_ABI,
  singleTokenAddress: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
};

export const CLASSIC_LIQ_CRONOS_CONTRACT = {
  abi: CLASSIC_LIQ_CRONOS_ABI,
  tokenAbi: CLASSIC_LIQ_CRONOS_ABI,
  singleTokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
};
